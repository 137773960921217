import {
    Row,
    Col,
    Typography,
    Button,
    Table,
    Breadcrumb,
    Divider,
    Tooltip,
} from "antd";
import {
    InfoCircleOutlined,
    ArrowLeftOutlined,
    PlusOutlined,
} from "@ant-design/icons";


import { useSelector } from "react-redux";
import { stateGlobal } from "../../../../../../../interfaces/Global";
import useAlternativasManage from "./useAlternativasManage";
import { ModalFormAlternativas } from "./ModalFormAlternativas";
import { IReqVotacoes } from "../interfaces";
import { useMemo } from "react";
import { ModalImagem } from "../../modals/ModalImagem";
import { TiArrowBackOutline } from "react-icons/ti";

const { Title, Text } = Typography;

interface IProps {
    refDataContrato: any,
    setViewScreenAlternativas: (state: boolean) => void,
    datasVotacoes: Array<IReqVotacoes>,
    arrIdVotacaoSelecionada: Array<number | string>,
}

export const AlternativasManage = ({
    refDataContrato,
    setViewScreenAlternativas,
    datasVotacoes,
    arrIdVotacaoSelecionada
}: IProps) => {

    const propsState = useSelector((state: stateGlobal) => state.global);

    const votacaoSelecionada: IReqVotacoes | null = useMemo(() => {

        if (arrIdVotacaoSelecionada.length == 1) {

            // let objSelecionado: IReqVotacoes | null = null
            // datasVotacoes.forEach((dados): void => {
            //     if (dados.id == arrIdVotacaoSelecionada[0]) {
            //         objSelecionado = dados
            //     }
            // })

            // return objSelecionado;

            return datasVotacoes.find(
                (dados) => dados.id === arrIdVotacaoSelecionada[0]
            ) || null;

        }
        return null;


    }, [datasVotacoes, arrIdVotacaoSelecionada])

    const {
        loadindTable,
        refContagemDoFiltroMembros,
        dataReq,
        columns,
        form,
        isModalOpen,
        handleCancelModal,
        refForm,
        loadingPage,
        onClickBotaoSalvarModal,
        onClickBotaoEditarModal,
        isEditarVotacao,
        setIsEditarVotacao,
        handleShowModal,
        rowSelection,
        onclickTable,
        hasSelectedEdit,
        selectedRowKeys,
        isModalImagemOpen,
        handleImagemCancel,
        imagemModal,
        beforeUploadImgAlternativa,
        fotoAlternativa,
    } = useAlternativasManage({ refDataContrato, votacaoSelecionada });


    return (
        <>


            <Row>
                <Col span={24}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Tooltip title="Voltar">
                            <Button
                                type="primary"
                                size='small'
                                style={{
                                    marginRight: 5
                                }}
                                onClick={() => {
                                    setViewScreenAlternativas(false)
                                }}
                            >
                                <TiArrowBackOutline size={20} />
                            </Button>
                        </Tooltip>
                        <Title level={3} style={{ margin: 0 }}>
                            Gerenciar Alternativas
                        </Title>
                    </div>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a
                                onClick={() =>
                                    setViewScreenAlternativas(false)
                                }
                            >
                                Votações
                            </a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item> Alternativas</Breadcrumb.Item>
                    </Breadcrumb>
                    {/* <Divider /> */}
                </Col>

                <Col
                    style={{
                        marginTop: 5
                    }}
                >
                    <Row>
                        {/* <Button
                            type="primary"
                            size="small"
                            // disabled={hasSelectedEdit || selectedRowKeys.length > 1}
                            style={{
                                marginRight: 5
                            }}

                            onClick={() => {
                                setViewScreenAlternativas(false)
                            }}
                        >
                            <ArrowLeftOutlined />
                            voltar para votações
                        </Button> */}

                        <Title level={5}>
                            <Text strong={false} style={{fontWeight: 'initial'}}>Votação:{" "}</Text>
                            {
                                votacaoSelecionada != null ?
                                    votacaoSelecionada?.titulo :
                                    ''
                            }
                        </Title>

                    </Row>
                </Col>
                <Col span={24} >
                    <Divider />
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Col
                    style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 5
                    }}
                >
                    <Button
                        type="primary"
                        disabled={hasSelectedEdit || selectedRowKeys.length > 1}
                        onClick={() => {
                            setIsEditarVotacao(false);

                            refForm.current?.resetFields();

                            handleShowModal();
                        }}
                    >
                        <PlusOutlined />
                        Adicionar alternativa
                    </Button>
                </Col>
                {/* <Col
                    style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 5
                    }}
                >
                    <Button
                        type="primary"
                        disabled={!hasSelectedEdit}
                        className="botaoGerenciarAlternativas_lcjhveycfiawecaeow"
                        onClick={() => {



                        }}
                    >
                        <SettingOutlined />
                        Gerenciar alternativas
                    </Button>
                </Col>
                <Col
                    style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 5
                    }}
                >
                    <Button
                        type="primary"
                        disabled={!hasSelectedEdit}
                        onClick={() => {


                        }}
                    >
                        <FileDoneOutlined />
                        Resultado da votação
                    </Button>
                </Col>*/}
            </Row>

            <Table
                style={{
                    marginTop: 10,
                }}
                className="marginTopTables"
                rowKey={(record) => Number(record?.id)}
                loading={loadindTable}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => { onclickTable(record, rowIndex) }, // click row
                //     };
                // }}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={dataReq}
                scroll={{ x: 1100 }}
                size="small"
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor:
                                    propsState.theme === "light"
                                        ? "#FAFAFA"
                                        : "#1D1D1D",
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5,
                            }}
                        >
                            <Text>
                                {`Mostrando: ${registros.length
                                    } de ${refContagemDoFiltroMembros.current.length === 0
                                        ? dataReq.length
                                        : refContagemDoFiltroMembros.current.length
                                    } num total de ${dataReq.length} `}
                            </Text>
                        </div>
                    );
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: "end",
                            }}
                        >
                            <Text>
                                {`Mostrando: ${registros.length
                                    } de ${refContagemDoFiltroMembros.current.length === 0
                                        ? dataReq.length
                                        : refContagemDoFiltroMembros.current.length
                                    } num total de ${dataReq.length} `}
                            </Text>
                        </div>
                    );
                }}
                pagination={{
                    locale: { items_per_page: "" },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ["topRight", "bottomRight"],
                    pageSizeOptions: ["10", "20", "50", "100"],
                }}
                locale={{
                    emptyText: (
                        <div style={{ marginTop: 10 }}>
                            <InfoCircleOutlined
                                style={{
                                    fontSize: 30,
                                    marginBottom: 10,
                                }}
                            />
                            <br />
                            <Text style={{ fontSize: 15 }}>
                                Nenhum registro encontrado!
                            </Text>
                        </div>
                    ),
                }}
            />

            <ModalFormAlternativas
                isModalOpen={isModalOpen}
                handleCancelModal={handleCancelModal}
                form={form}
                refForm={refForm}
                onClickBotaoSalvarModal={onClickBotaoSalvarModal}
                onClickBotaoEditarModal={onClickBotaoEditarModal}
                loadingPage={loadingPage}
                isEditarVotacao={isEditarVotacao}

                beforeUploadImgAlternativa={beforeUploadImgAlternativa}
                fotoAlternativa={fotoAlternativa}

            />

            <ModalImagem
                isOpen={isModalImagemOpen}
                handleCancel={handleImagemCancel}
                srcImage={imagemModal}
            />
        </>
    )
};
