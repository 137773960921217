import { Button, Modal } from "antd";

export const ModalImagem = ({ isOpen, handleCancel, srcImage }: any) => {

    const downloadImage = (url: string) => {
        // Faz uma solicitação para buscar os dados da imagem
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                // Cria um elemento 'a' para iniciar o download
                const element = document.createElement("a");
                element.href = URL.createObjectURL(blob);
                element.download = "imagem.jpg";
                element.click();
            })
            .catch((error) => {
                window.open(url);
            });
    };

    return (
        <Modal
            title="Vizualizar Imagem"
            width={500}
            footer={[]}
            visible={isOpen}
            onOk={handleCancel}
            onCancel={handleCancel}
        >
            <img src={srcImage} alt="avatar" style={{ width: "100%", marginBottom: 10 }} />
            <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                    downloadImage(
                        srcImage
                    );
                }}
            >
                Baixar Imagem
            </Button>
        </Modal>
    );
}
