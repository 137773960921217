import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { notification } from "antd";
import { urlEntidadeVotacao } from "../../../../../../../../services/request/urls";
import axios from "axios";
import { getToken } from "../../../../../../../../services/token";
import { IReqResultadoVotacoes, IReqVotacoes } from "./../../interfaces";
import { isTodayWithinRange } from "./funcoes";

interface IProps {
    refDataContrato: any
    votacaoSelecionada: IReqVotacoes | null,
    isModalOpen: boolean
}

export default function useModalResultadoVotacoes({
    refDataContrato,
    votacaoSelecionada,
    isModalOpen
}: IProps) {

    const [loadingPage, setLoadingPage] = useState(false);
    const [dataReq, setDataReq] = useState<IReqResultadoVotacoes>();

    const getResultadoVotacoes = useCallback(() => {
        const requestAsync = async () => {
            try {

                if (votacaoSelecionada) {
                    setLoadingPage(true);
                    let { data } = await axios.get(
                        urlEntidadeVotacao(refDataContrato.current?.contrato_entidade.id) +
                        "/" + votacaoSelecionada.id + "/resultados"
                        ,
                        { headers: { Authorization: "Bearer " + getToken() } }
                    );

                    setDataReq(data);

                    setLoadingPage(false);
                }
            } catch (error) {
                setLoadingPage(false);

                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        return requestAsync();
    }, [votacaoSelecionada]);


    useEffect(() => {

        const buscaDadosIniciais = async () => {
            try {

                if (isModalOpen) {
                    getResultadoVotacoes();
                }
            } catch (error) {
                setLoadingPage(false);
                notification.error({
                    message: "Erro",
                    description: "Não foi possivel buscar dados!",
                });
            }
        };

        buscaDadosIniciais();

    }, [isModalOpen]);

    const totalDeVotos = useMemo(() => dataReq?.resultado.reduce((soma, res) => soma + parseInt(res.votos, 10), 0), [dataReq])

    const isOpenVotacao = useMemo(() => isTodayWithinRange(dataReq?.dataInicio, dataReq?.dataFim), [dataReq])


    const data = useMemo(() => {
        if (dataReq) {
            // Encontrar o maior valor de votos
            const maiorVotos = Math.max(...dataReq.resultado.map(item => parseInt(item.votos, 10)));

            // Contar quantas alternativas possuem o maior número de votos (para verificar empate)
            const qtdMaiores = dataReq.resultado.filter(item => parseInt(item.votos, 10) === maiorVotos).length;

            // Adicionar o atributo "vencedor"
            const resultado = dataReq.resultado.map(item => {
                const votos = parseInt(item.votos, 10);

                let status, cor;
                if (votos === maiorVotos) {
                    status = qtdMaiores > 1 ? "empate" : "vencedor";
                    cor = qtdMaiores > 1 ? "#ffc107" : "#49AA19";
                } else {
                    status = "derrota";
                    cor = "#177DDC";
                    // cor = "#dc3545";
                }

                return {
                    ...item,
                    vencedor: status,
                    cor: cor
                };
            });

            return {
                ...dataReq,
                resultado: resultado
            }
        }

        return undefined

    }, [dataReq])

    // const generateCSVResultado = useCallback((data: Array<any>, delimiter = ',') => {
    //     if (!data || !data.length) return;

    //     // Cria os cabeçalhos do CSV
    //     const headers = Object.keys(data[0]).join(delimiter);

    //     // Cria as linhas do CSV
    //     const rows = data.map(row => {
    //         return Object.values(row)
    //             .map(value => `"${value}"`) // Escapa valores
    //             .join(delimiter); // Junta os valores com o delimitador correto
    //     });

    //     // Junta cabeçalhos e linhas no formato CSV
    //     const csvContent = [headers, ...rows].join('\n');

    //     // Cria o blob do arquivo CSV
    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //     const url = URL.createObjectURL(blob);

    //     // Cria o link para download
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'dados.csv'); // Nome do arquivo CSV
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);

    // }, [])

    const flattenObject = useCallback((obj, parentKey = "", result = {}) => {
        for (const key in obj) {
            if (Object.hasOwn(obj, key)) {
                const newKey = parentKey ? `${parentKey}.${key}` : key;

                if (typeof obj[key] === "object" && obj[key] !== null) {
                    flattenObject(obj[key], newKey, result);
                } else {
                    result[newKey] = obj[key];
                }
            }
        }
        return result;
    }, [])

    const generateCSVRegistros = useCallback((data: Array<any>) => {
        // Flatten each object in the array
        const flattenedData = data.map((item) => flattenObject(item));

        // Generate CSV headers dynamically
        const headers = Array.from(
            new Set(flattenedData.flatMap((obj) => Object.keys(obj)))
        ).join(",");

        // Generate CSV rows
        const rows = flattenedData.map((row) =>
            headers
                .split(",")
                .map((key) => `"${row[key] || ""}"`) // Use empty string if key is missing
                .join(",")
        );

        // Combine headers and rows
        return [headers, ...rows].join("\n");
    }, [])

    const baixarCSVRegistros = useCallback((data: Array<any>, filename?: string) => {
        const csvContent = generateCSVRegistros(data);
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = filename || "data.csv"; // Default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the URL object
        URL.revokeObjectURL(url);

    }, [])


    const formatRegistroVotos = useMemo(() => {

        if (dataReq?.registroVotos && dataReq?.registroVotos.length > 0) {

            return dataReq?.registroVotos.map((todos) => ({
                id: todos?.id,
                usuarioId: todos?.userId,
                cargo: todos?.user.cargo.nome,
                celular: todos?.user?.celular,
                nome: todos?.user.nome,
                votacaoId: todos?.votacaoId,
                timeStampValidacao: todos?.timeStampValidacao,
                created_at: todos?.created_at,
            }));
        }
        return []
    }, [dataReq])

    const formatVotos = useMemo(() => {

        if (dataReq?.votos && dataReq?.votos.length > 0) {

            return dataReq?.votos.map((todos) => ({
                id: todos?.id,
                votacaoId: todos?.votacaoId,
                alternativaVotacaoId: todos?.alternativaVotacaoId,
                hash: todos?.hash,
            }));
        }
        return []
    }, [dataReq])

    return {
        data,
        loadingPage,
        isOpenVotacao,
        totalDeVotos,
        dataReq,
        generateCSVRegistros,
        baixarCSVRegistros,
        formatRegistroVotos,
        formatVotos
    }
}
