import { isWithinInterval, parseISO } from "date-fns";

export const isTodayWithinRange = (dataInicio?: string | null, dataFim?: string | null) => {

    if (dataInicio && dataFim) {
        const today = new Date(); // Obtém a data atual

        return isWithinInterval(today, {
            start: parseISO(dataInicio),
            end: parseISO(dataFim),
        });
    }

    return false

}
