import {
    EditOutlined,
    InfoCircleOutlined,
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Input,
    List,
    Modal,
    Pagination,
    Row,
    Table,
    Typography,
    notification,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { convertDataUSParaBrasilEHora } from "../../../../services/Conversores";
import { urlContratoAtivos, urlContratosEntidade } from "../../../../services/request/urls";
import axios from "axios";
import { getToken } from "../../../../services/token";
import { useSelector } from "react-redux";
import { stateGlobal } from "../../../../interfaces/Global";
import { useHistory } from "react-router";

export default function ListagemEntidade() {
    const { Title, Text } = Typography;
    const [loadingTableEntidades, setLoadingTableEntidades] = useState(false);
    const [entidades, setEntidades] = useState([]);
    const [contratos, setContratos] = useState([]);
    const propsState = useSelector((state: stateGlobal) => state.global);
    const refContagemDoFiltro = useRef<Array<any>>([]);
    const history = useHistory();
    const signal = axios.CancelToken.source();
    const [isModalContratoOpen, setIsModalContratoOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Função para calcular o índice inicial e final da página atual
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleSearch = (value: any) => {
        setSearchTerm(value);
        setCurrentPage(1);
    };

    const removeAccents = (str: any) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };


    const includesIgnoreCase = (str1: any, str2: any) => {
        return removeAccents(str1.toLowerCase()).includes(removeAccents(str2.toLowerCase()));
    };

    const filteredContratos = contratos.filter((item: any) =>
        includesIgnoreCase(item.nome, searchTerm)
    );

    const paginatedContratos = filteredContratos.slice(startIndex, endIndex);


    const getEntidades = useCallback(() => {
        setLoadingTableEntidades(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratosEntidade, {
                    headers: { Authorization: "Bearer " + getToken() },
                });

                setEntidades(result.data);
            } catch (error) {
                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        setLoadingTableEntidades(false);
        return buscaDeDados();
    }, []);

    const getContratos = async () => {
        try {
            let result = await axios.get(urlContratoAtivos,
                {
                    headers: { 'Authorization': 'Bearer ' + getToken() },
                    cancelToken: signal.token
                });

            setContratos(result.data);


        } catch (error) {
            let msgErro: any = (error as Error);
            if (msgErro?.message != 'Requisicao cancelada!') {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }
    }

    const comonentDidMount = useEffect((): void => {
        const buscaDadosIniciais = async () => {
            getEntidades();
            getContratos();
        };

        buscaDadosIniciais();
    }, []);

    const getColumnSearchProps = useCallback(
        (dataIndex: any) => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => { }}
                        placeholder={`Buscar ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            );
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm();
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={clearFilters}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: any) => (
                <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                />
            ),
            onFilter: (value: any, record: any) =>
                record[dataIndex]
                    ? record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : "",
        }),
        []
    );
    const columnsEntidades = [
        {
            title: "Contrato",
            dataIndex: "nome",
            width: "30%",
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            ...getColumnSearchProps("nome"),
            showSorterTooltip: false,
        },
        {
            title: "Entidade",
            dataIndex: "contratoEntidade",
            width: "30%",
            sorter: (a: any, b: any) =>
                a.contratoEntidade.nome.localeCompare(b.contratoEntidade.nome),
            render: (contratoEntidade: any) => contratoEntidade.nome,
            ...getColumnSearchProps("contratoEntidade"),
            showSorterTooltip: false,
        },
        {
            title: "Data de Cadastro",
            dataIndex: "created_at",
            width: "15%",
            sorter: (a: any, b: any) =>
                moment(a.created_at).unix() - moment(b.created_at).unix(),
            render: (created_at: any) =>
                convertDataUSParaBrasilEHora(created_at),
            showSorterTooltip: false,
        },
        {
            title: "Ações",
            dataIndex: "id",
            width: "7%",
            render: (id: any, record: any) => (
                <span>
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        size="small"
                        onClick={() => {
                            //Abre a página contrato/modulo/id
                            history.push(`/contrato/modulo/${id}`);
                        }}
                    >
                        <EditOutlined />
                    </Button>
                    {/* <Divider type="vertical" />
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        danger
                        size="small"
                        onClick={() => {
                            // excluirNoticia(id);
                        }}
                    >
                        <BiTrash />
                    </Button> */}
                </span>
            ),
        },
    ];

    return (
        <>
            <Title level={2}>Entidades</Title>
            <Row style={{ marginTop: 10 }}>
                <Col
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsModalContratoOpen(true);
                        }}
                    >
                        <PlusOutlined />
                        Nova Entidade
                    </Button>
                </Col>
            </Row>

            <Table
                style={{
                    marginTop: 10,
                }}
                className="marginTopTables"
                rowKey={(record) => Number(record?.id)}
                loading={loadingTableEntidades}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => { onclickTableNoticias(record, rowIndex) }, // click row
                //     };
                // }}
                // rowSelection={rowSelection}
                columns={columnsEntidades}
                dataSource={entidades}
                scroll={{ x: 1100 }}
                size="small"
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor:
                                    propsState.theme === "light"
                                        ? "#FAFAFA"
                                        : "#1D1D1D",
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5,
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0
                                    ? entidades.length
                                    : refContagemDoFiltro.current.length
                                    } num total de ${entidades.length} `}
                            </Text>
                        </div>
                    );
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: "end",
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0
                                    ? entidades.length
                                    : refContagemDoFiltro.current.length
                                    } num total de ${entidades.length} `}
                            </Text>
                        </div>
                    );
                }}
                pagination={{
                    locale: { items_per_page: "" },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ["topRight", "bottomRight"],
                    pageSizeOptions: ["10", "20", "50", "100"],
                }}
                locale={{
                    emptyText: (
                        <div style={{ marginTop: 10 }}>
                            <InfoCircleOutlined
                                style={{
                                    fontSize: 30,
                                    marginBottom: 10,
                                }}
                            />
                            <br />
                            <Text style={{ fontSize: 15 }}>
                                Nenhum registro encontrado!
                            </Text>
                        </div>
                    ),
                }}
            />

            <Modal
                title="Selecione o contrato"
                visible={isModalContratoOpen}
                onCancel={() => {
                    setIsModalContratoOpen(false);
                }}
                footer={null}
                width={800}
            >
                <div>
                    <Input.Search

                        placeholder="Buscar contrato"
                        onSearch={handleSearch}
                        enterButton
                    />
                    {filteredContratos.length === 0 ? (
                        <p>Nenhum contrato encontrado.</p>
                    ) : (
                        <List
                            style={{
                                marginTop: 10,
                                maxHeight: 500,
                                overflowY: "auto",
                                overflowX: "hidden",
                                border: "1px solid #ddd",
                                borderRadius: 5,
                            }}
                            size="small"
                            bordered
                            dataSource={paginatedContratos}
                            renderItem={(item: any) => (
                                <List.Item
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    actions={[
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                // Abre a página contrato/modulo/id
                                                history.push(`/contrato/modulo/${item.id}`);
                                            }}
                                        >
                                            Selecionar
                                        </Button>
                                    ]}
                                >
                                    <Text>{item.nome}</Text>
                                </List.Item>
                            )}
                        />
                    )}
                    <Pagination
                        style={{ marginTop: 10 }}
                        current={currentPage}
                        onChange={handlePageChange}
                        pageSize={itemsPerPage}
                        total={contratos.length}
                        showSizeChanger={false}
                    />
                </div>

                <Divider />
                <Row justify="end">
                    <Col>
                        <Button
                            onClick={() => {
                                setIsModalContratoOpen(false);
                            }}
                        >
                            Cancelar
                        </Button>
                    </Col>
                </Row>
            </Modal>


        </>
    );
}
