import { Button, Col, Divider, Modal, notification, Progress, Row, Spin, Tooltip, Typography } from "antd";
import useModalResultadoVotacoes from "./useModalResuldatoVotacoes";
import { IReqVotacoes } from "../../interfaces";
import { useMemo } from "react";
import moment from "moment";
import ProgressBar from "@ramonak/react-progress-bar";
import { useSelector } from "react-redux";
import { stateGlobal } from "../../../../../../../../interfaces/Global";
import { DownloadOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

interface IProps {
    isModalOpen: boolean
    handleCancelModal: () => void
    datasVotacoes: Array<IReqVotacoes>,
    arrIdVotacaoSelecionada: Array<number | string>,
    refDataContrato: any,
}

export const ModalResultadoVotacoes = ({
    isModalOpen,
    handleCancelModal,
    datasVotacoes,
    arrIdVotacaoSelecionada,
    refDataContrato
}: IProps) => {

    const propsState = useSelector((state: stateGlobal) => state.global);

    const votacaoSelecionada: IReqVotacoes | null = useMemo(() => {

        if (arrIdVotacaoSelecionada.length == 1) {

            return datasVotacoes.find(
                (dados) => dados.id === arrIdVotacaoSelecionada[0]
            ) || null;

        }
        return null;


    }, [datasVotacoes, arrIdVotacaoSelecionada])


    const {
        loadingPage,
        data,
        isOpenVotacao,
        totalDeVotos,
        dataReq,
        baixarCSVRegistros,
        formatRegistroVotos,
        formatVotos,
    } = useModalResultadoVotacoes({ refDataContrato, votacaoSelecionada, isModalOpen });


    return (
        <Modal
            title={"Resultado da votação"}
            width={1000}
            footer={false}
            visible={isModalOpen}
            onOk={handleCancelModal}
            onCancel={handleCancelModal}
            destroyOnClose
        >
            <Spin spinning={loadingPage}>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        {
                            data?.titulo && (
                                <Title
                                    level={4}
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                        paddingRight: 40
                                    }}
                                >
                                    {data?.titulo}
                                </Title>
                            )
                        }

                        <Title
                            level={5}
                            style={{
                                margin: 0,
                                padding: 0,
                                // fontWeight: 'bold',
                                color: isOpenVotacao ? "#27AE60" : "#EB5757"
                            }}
                        >
                            {isOpenVotacao ? 'Aberta' : 'Fechada'}
                        </Title>
                    </div>
                    {
                        data?.descricao && (
                            <Text>
                                {data?.descricao}
                            </Text>
                        )
                    }
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                            }}
                        >
                            {
                                data?.dataInicio && (
                                    <Text
                                        strong={false}
                                        style={{
                                            marginRight: 10
                                        }}
                                    >
                                        <Text strong style={{ marginRight: 5 }}>Data início: </Text>
                                        {moment(data?.dataInicio).format("DD/MM/YYYY HH:mm")}
                                    </Text>
                                )
                            }
                            {
                                data?.dataFim && (
                                    <Text
                                        strong={false}
                                    >
                                        <Text strong style={{ marginRight: 5 }}>Data fim: </Text>
                                        {moment(data?.dataFim).format("DD/MM/YYYY HH:mm")}
                                    </Text>
                                )
                            }
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                            }}
                        >
                            <Tooltip title="Baixar">
                                <Button
                                    type="primary"
                                    style={{
                                        marginRight: 5,
                                        marginBottom: 5
                                    }}
                                    disabled={!dataReq?.resultado || dataReq?.resultado.length <= 0}
                                    onClick={() => {
                                        if (dataReq?.resultado && dataReq?.resultado.length > 0) {
                                            const titulo = dataReq?.titulo.replace(/\s+/g, "");
                                            baixarCSVRegistros(dataReq?.resultado, `Resultado-${titulo}-${moment(data?.dataInicio).format("DD-MM-YYYY_HH-mm")}.csv`.trim())
                                        } else {
                                            notification.warning({
                                                message: "Atenção",
                                                description: "Não existem dados a serem baixados.",
                                            });
                                        }
                                    }}
                                >
                                    <DownloadOutlined />
                                    Resultado
                                </Button>
                            </Tooltip>
                            <Tooltip title="Baixar">
                                <Button
                                    type="primary"
                                    style={{
                                        marginRight: 5,
                                        marginBottom: 5
                                    }}
                                    disabled={!formatVotos || formatVotos.length <= 0}
                                    onClick={() => {
                                        if (formatVotos && formatVotos.length > 0) {
                                            const titulo = dataReq?.titulo.replace(/\s+/g, "");
                                            baixarCSVRegistros(formatVotos, `Votos-${titulo}-${moment(data?.dataInicio).format("DD-MM-YYYY_HH-mm")}.csv`.trim())
                                        } else {
                                            notification.warning({
                                                message: "Atenção",
                                                description: "Não existem dados a serem baixados.",
                                            });
                                        }
                                    }}
                                >
                                    <DownloadOutlined />
                                    Votos
                                </Button>
                            </Tooltip>
                            <Tooltip title="Baixar">
                                <Button
                                    type="primary"
                                    disabled={!formatRegistroVotos || formatRegistroVotos.length <= 0}
                                    onClick={() => {
                                        if (formatRegistroVotos && formatRegistroVotos.length > 0) {
                                            const titulo = dataReq?.titulo.replace(/\s+/g, "");
                                            baixarCSVRegistros(formatRegistroVotos, `RegistroDeEleitores-${titulo}-${moment(data?.dataInicio).format("DD-MM-YYYY_HH-mm")}.csv`)
                                        } else {
                                            notification.warning({
                                                message: "Atenção",
                                                description: "Não existem dados a serem baixados.",
                                            });
                                        }
                                    }}
                                >
                                    <DownloadOutlined />
                                    Eleitores
                                </Button>
                            </Tooltip>

                        </div>
                    </div>
                </div>
                <Divider />

                <Row>
                    <Col span={24} style={{ marginBottom: 5 }}>
                        <Title level={5} style={{ marginRight: 5 }}>Alternativas: </Title>
                    </Col>
                </Row>
                {
                    data?.resultado.map((result, index) => {
                        let percentage = null;

                        if (result?.votos && totalDeVotos) {
                            percentage = ((parseInt(result?.votos) / totalDeVotos) * 100).toFixed(2);
                        }
                        return (
                            <Row key={result.alternativaid}>
                                {
                                    result?.alternativa_titulo && (
                                        <Col span={24} style={{ marginBottom: 5 }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    paddingTop: 5,
                                                    paddingBottom: 5
                                                }}
                                            >
                                                <Text strong={false} style={{ marginRight: 5, paddingRight: 40 }}>
                                                    {result?.alternativa_titulo}
                                                </Text>
                                                {
                                                    result?.votos && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap: 'wrap',
                                                                flexDirection: 'row',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Text
                                                                strong={false}
                                                            >
                                                                {'Quantidade de votos: '}
                                                                <Text strong={true} style={{ marginRight: 5, marginLeft: 5 }}>
                                                                    {result?.votos}
                                                                </Text>
                                                            </Text>
                                                            {
                                                                percentage && (
                                                                    <>
                                                                        <Divider type="vertical" />
                                                                        <Text
                                                                            strong={false}
                                                                        >
                                                                            <Text strong={true} style={{ marginRight: 5, marginLeft: 5 }}>
                                                                                {percentage}
                                                                            </Text>
                                                                            {'%'}
                                                                        </Text>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            {
                                                result?.votos && (
                                                    <ProgressBar
                                                        completed={parseInt(result?.votos)}
                                                        maxCompleted={totalDeVotos}
                                                        isLabelVisible={false}
                                                        // bgColor={result.vencedor ? "#49AA19" : "#177DDC"}
                                                        bgColor={result.cor}
                                                        baseBgColor={propsState.theme === 'light' ? "#e4e6e9" : "#313131"}
                                                    />
                                                )
                                            }

                                            <Divider style={{ margin: 0, padding: 0, marginTop: 10 }} />
                                        </Col>
                                    )
                                }
                            </Row>
                        )
                    })
                }
                <Row>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flex: 1
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        width: 10,
                                        height: 10,
                                        backgroundColor: '#49AA19',
                                        borderRadius: 8
                                    }}
                                />
                                <Text strong={false} type="secondary" style={{ paddingLeft: 5, paddingRight: 5, margin: 0 }}>
                                    {'Primeiro'}
                                </Text>
                                <Divider type="vertical" style={{ margin: 0, padding: 0, paddingLeft: 5, }} />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        width: 10,
                                        height: 10,
                                        backgroundColor: '#ffc107',
                                        borderRadius: 8,
                                    }}
                                />
                                <Text strong={false} type="secondary" style={{ paddingLeft: 5, paddingRight: 5, margin: 0 }}>
                                    {'Empate'}
                                </Text>
                                <Divider type="vertical" style={{ margin: 0, padding: 0, paddingLeft: 5, }} />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        width: 10,
                                        height: 10,
                                        backgroundColor: '#177DDC',
                                        borderRadius: 8
                                    }}
                                />
                                <Text strong={false} type="secondary" style={{ paddingLeft: 5, paddingRight: 5, margin: 0 }}>
                                    {'Outros'}
                                </Text>
                            </div>
                        </div>
                        <div>
                            <Text
                                strong={false}
                                style={{
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {'Total: '}
                                <Text strong={true} style={{ marginRight: 5, marginLeft: 5 }}>
                                    {totalDeVotos}
                                </Text>
                            </Text>
                        </div>
                    </div>
                </Row>
            </Spin>
        </Modal >
    );
}
