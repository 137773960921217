import {
    Row,
    Col,
    Typography,
    Button,
    Table,
} from "antd";
import {
    PlusOutlined,
    InfoCircleOutlined,
    FileDoneOutlined,
    SettingOutlined,
} from "@ant-design/icons";

import { useSelector } from "react-redux";
import { stateGlobal } from "../../../../../../interfaces/Global";
import useVotacoesManage from "./useVotacoesManage";
import { ModalFormVotacoes } from "./components/ModalFormVotacoes";
import { AlternativasManage } from "./AlternativasManage";
import { ModalResultadoVotacoes } from "./components/ModalResultadoVotacoes";

const { Title, Text } = Typography;

interface IProps {
    refDataContrato: any
}

export const VotacoesManage = ({
    refDataContrato,

}: IProps) => {

    const propsState = useSelector((state: stateGlobal) => state.global);

    const {
        loadindTable,
        refContagemDoFiltroMembros,
        dataReq,
        columns,
        form,
        isModalOpen,
        handleCancelModal,
        refForm,
        loadingPage,
        onClickBotaoSalvarModal,
        onClickBotaoEditarModal,
        isEditarVotacao,
        setIsEditarVotacao,
        handleShowModal,
        setSelectedDateInicio,
        selectedDateInicio,
        setSelectedDateFim,
        selectedDateFim,
        rowSelection,
        onclickTable,
        refFormSelectCargos,
        refBotaoSeleCargos,
        dadosCargosList,
        onClickAddTodosSeleCargos,
        hasSelectedEdit,
        selectedRowKeys,
        setViewScreenAlternativas,
        viewScreenAlternativas,
        isModalOpenResultadoVotacoes,
        handleCancelModalResultadoVotacoes,
        handleShowModalResultadoVotacoes,
    } = useVotacoesManage({ refDataContrato });


    return (
        <>
            {
                !viewScreenAlternativas ?
                    <>
                        <Title level={3}>
                            Votações da:{" "}
                            {
                                refDataContrato.current?.contrato_entidade
                                    ?.nome
                            }
                        </Title>
                        <Row style={{ marginTop: 10 }}>
                            <Col
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: 5
                                }}
                            >
                                <Button
                                    type="primary"
                                    disabled={hasSelectedEdit || selectedRowKeys.length > 1}
                                    onClick={() => {
                                        setIsEditarVotacao(false);

                                        setSelectedDateInicio(null)
                                        setSelectedDateFim(null)


                                        refForm.current?.resetFields();

                                        handleShowModal();
                                    }}
                                >
                                    <PlusOutlined />
                                    Adicionar votação
                                </Button>
                            </Col>
                            <Col
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: 5
                                }}
                            >
                                <Button
                                    type="primary"
                                    disabled={!hasSelectedEdit}
                                    className="botaoGerenciarAlternativas_lcjhveycfiawecaeow"
                                    onClick={() => {

                                        if (selectedRowKeys.length == 1) {

                                            setViewScreenAlternativas(true)

                                        }

                                    }}
                                >
                                    <SettingOutlined />
                                    Gerenciar alternativas
                                </Button>
                            </Col>
                            <Col
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: 5
                                }}
                            >
                                <Button
                                    type="primary"
                                    disabled={!hasSelectedEdit}
                                    onClick={() => {

                                        handleShowModalResultadoVotacoes();
                                    }}
                                >
                                    <FileDoneOutlined />
                                    Resultado da votação
                                </Button>
                            </Col>
                        </Row>

                        <Table
                            style={{
                                marginTop: 10,
                            }}
                            className="marginTopTables"
                            rowKey={(record) => Number(record?.id)}
                            loading={loadindTable}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => { onclickTable(record, rowIndex) }, // click row
                                };
                            }}
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={dataReq}
                            scroll={{ x: 1100 }}
                            size="small"
                            title={(registros) => {
                                return (
                                    <div
                                        style={{
                                            backgroundColor:
                                                propsState.theme === "light"
                                                    ? "#FAFAFA"
                                                    : "#1D1D1D",
                                            padding: 10,
                                            margin: -8,
                                            marginBottom: 0.5,
                                        }}
                                    >
                                        <Text>
                                            {`Mostrando: ${registros.length
                                                } de ${refContagemDoFiltroMembros.current.length === 0
                                                    ? dataReq.length
                                                    : refContagemDoFiltroMembros.current.length
                                                } num total de ${dataReq.length} `}
                                        </Text>
                                    </div>
                                );
                            }}
                            footer={(registros) => {
                                return (
                                    <div
                                        style={{
                                            textAlign: "end",
                                        }}
                                    >
                                        <Text>
                                            {`Mostrando: ${registros.length
                                                } de ${refContagemDoFiltroMembros.current.length === 0
                                                    ? dataReq.length
                                                    : refContagemDoFiltroMembros.current.length
                                                } num total de ${dataReq.length} `}
                                        </Text>
                                    </div>
                                );
                            }}
                            pagination={{
                                locale: { items_per_page: "" },
                                showSizeChanger: true,
                                defaultPageSize: 100,
                                position: ["topRight", "bottomRight"],
                                pageSizeOptions: ["10", "20", "50", "100"],
                            }}
                            locale={{
                                emptyText: (
                                    <div style={{ marginTop: 10 }}>
                                        <InfoCircleOutlined
                                            style={{
                                                fontSize: 30,
                                                marginBottom: 10,
                                            }}
                                        />
                                        <br />
                                        <Text style={{ fontSize: 15 }}>
                                            Nenhum registro encontrado!
                                        </Text>
                                    </div>
                                ),
                            }}
                        />

                        <ModalFormVotacoes
                            isModalOpen={isModalOpen}
                            handleCancelModal={handleCancelModal}
                            form={form}
                            refForm={refForm}
                            onClickBotaoSalvarModal={onClickBotaoSalvarModal}
                            onClickBotaoEditarModal={onClickBotaoEditarModal}
                            loadingPage={loadingPage}
                            isEditarVotacao={isEditarVotacao}

                            setSelectedDateInicio={setSelectedDateInicio}
                            selectedDateInicio={selectedDateInicio}
                            setSelectedDateFim={setSelectedDateFim}
                            selectedDateFim={selectedDateFim}

                            onClickAddTodosSeleCargos={onClickAddTodosSeleCargos}
                            refFormSelectCargos={refFormSelectCargos}
                            refBotaoSeleCargos={refBotaoSeleCargos}
                            dadosCargosList={dadosCargosList}
                        />

                        <ModalResultadoVotacoes
                            isModalOpen={isModalOpenResultadoVotacoes}
                            handleCancelModal={handleCancelModalResultadoVotacoes}
                            datasVotacoes={dataReq}
                            arrIdVotacaoSelecionada={selectedRowKeys}
                            refDataContrato={refDataContrato}
                        />

                    </>
                    :
                    <AlternativasManage
                        datasVotacoes={dataReq}
                        arrIdVotacaoSelecionada={selectedRowKeys}
                        refDataContrato={refDataContrato}
                        setViewScreenAlternativas={setViewScreenAlternativas}
                    />
            }
        </>
    )
};
