import { Button, Col, Divider, Form, Input, Modal, Row, Select, Spin } from "antd";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { colInputs } from "./../GridStyle";
import { removeAcento } from "../../../../../../../utils/RemoveAcentos";


export const ModalFormVotacoes = ({
    isModalOpen,
    handleCancelModal,
    form,
    refForm,
    onClickBotaoSalvarModal,
    onClickBotaoEditarModal,
    loadingPage,
    isEditarVotacao,

    setSelectedDateInicio,
    selectedDateInicio,
    setSelectedDateFim,
    selectedDateFim,

    onClickAddTodosSeleCargos,
    refFormSelectCargos,
    refBotaoSeleCargos,
    dadosCargosList,
}: any) => {

    return (
        <Modal
            title={isEditarVotacao ? "Editar votação" : "Adicionar votação"}
            width={1000}
            footer={false}
            visible={isModalOpen}
            onOk={handleCancelModal}
            onCancel={handleCancelModal}
            destroyOnClose
        >
            <Spin spinning={loadingPage}>
                <Form
                    form={form}
                    ref={refForm}
                    name="formVotacao"
                    layout="vertical"
                    onFinishFailed={() => { }}
                >
                    <Row>
                        {/* <Col hidden {...inputNome}>
                        <Form.Item
                            name="idMembro"
                            label="id"
                            rules={[
                                {
                                    required: false,
                                    message: "Por favor preencha o id!",
                                },
                            ]}
                        >
                            <Input placeholder="id" disabled />
                        </Form.Item>
                    </Col> */}
                        <Col {...colInputs}>
                            <Form.Item
                                name="titulo"
                                label="Título"
                                style={{ margin: 5 }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, digite o título.",
                                    },
                                ]}
                            >
                                <Input placeholder="Eleições" />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="descricao"
                                label="Descrição"
                                style={{ margin: 5 }}
                                rules={[
                                    {
                                        required: false,
                                        message:
                                            "Por favor, digite a descrição.",
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    style={{ maxHeight: 150, height: 20 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="dataInicio"
                                label="Data início"
                                style={{ margin: 5 }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, selecione a data.",
                                    }
                                ]}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DateTimePicker
                                        value={selectedDateInicio}
                                        onChange={setSelectedDateInicio}
                                        required={false}
                                        // disabled={disabledSelectedInicio}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy HH:mm"
                                        className="datePickerCOlors"
                                        ampm={false}

                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="dataFim"
                                label="Data fim"
                                style={{ margin: 5 }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, selecione a data.",
                                    }
                                ]}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DateTimePicker
                                        value={selectedDateFim}
                                        onChange={setSelectedDateFim}
                                        required={false}
                                        // disabled={disabledSelectedFim}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy HH:mm"
                                        className="datePickerCOlors"
                                        ampm={false}

                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="cargosPermitidosIds"
                                label="Cargos permitidos"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, selecione um cargo.",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    ref={refFormSelectCargos}
                                    // loading={loadingUsuariosSelected}
                                    // notFoundContent={loadingUsuariosSelected ? <Spin size="small" /> : null}
                                    optionFilterProp="children"
                                    placeholder="Selecione..."
                                    mode="multiple"
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                    dropdownRender={menu => (
                                        <div>

                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Button
                                                    ref={refBotaoSeleCargos}
                                                    block
                                                    onClick={onClickAddTodosSeleCargos}
                                                    type="dashed"
                                                >Todos</Button>
                                            </div>
                                            <Divider style={{ margin: '4px 0' }} />
                                            {menu}
                                        </div>
                                    )}
                                >
                                    {
                                        dadosCargosList.map((item: any) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: "end" }}>
                            <div
                                style={{ textAlign: "end", marginRight: 5, marginTop: 10 }}
                            >

                                <Button
                                    style={{ marginRight: 10 }}
                                    onClick={handleCancelModal}
                                >
                                    Cancelar
                                </Button>
                                {isEditarVotacao ? (
                                    <Button
                                        type="primary"
                                        loading={loadingPage}
                                        onClick={onClickBotaoEditarModal}
                                    >
                                        Atualizar
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        loading={loadingPage}
                                        onClick={onClickBotaoSalvarModal}
                                    >
                                        Salvar
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}
