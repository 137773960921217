export const cardLayout = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 24, offset: 0 }, // > 1200
    xxl: { span: 24, offset: 0 }, // > 1600
};

export const modalAddModuloInputs = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 8, offset: 0 }, // >768
    lg: { span: 8, offset: 0 }, // > 992
    xl: { span: 8, offset: 0 }, // > 1200
    xxl: { span: 8, offset: 0 }, // > 1600
};
export const colBotaoAddEEditar = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 24, offset: 0 }, // > 992
    xl: { span: 10, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const colBotaoSelecionarInativos = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 24, offset: 0 }, // >768
    lg: { span: 4, offset: 0 }, // > 992
    xl: { span: 4, offset: 0 }, // > 1200
    xxl: { span: 4, offset: 0 }, // > 1600
};

export const colBotaoSelecionar = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 12, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 14, offset: 0 }, // > 1200
    xxl: { span: 14, offset: 0 }, // > 1600
};

/**
 * Inputs de vincular usuario
 */
export const colModalVincularUsuarioInputs = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};
export const colModalVincularUsuarioInputs2 = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 11, offset: 1 }, // >768
    lg: { span: 11, offset: 1 }, // > 992
    xl: { span: 11, offset: 1 }, // > 1200
    xxl: { span: 11, offset: 1 }, // > 1600
};
