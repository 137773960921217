import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Divider, Form, message, notification, Popconfirm, Popover, Typography } from "antd";
import { useGetColumnSearchProps } from "../../../../../../../hooks/FilterTable/stringCLearFiltros";
import moment from "moment";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { urlEntidadeVotacao } from "../../../../../../../services/request/urls";
import axios from "axios";
import { getToken } from "../../../../../../../services/token";
import { IReqVotacoes } from "./../interfaces";
import { IReqAlternativas } from "./interfaces";
import { BiTrash } from "react-icons/bi";
const { Text } = Typography;

interface IProps {
    refDataContrato: any,
    votacaoSelecionada: IReqVotacoes | null,
}

export default function useAlternativasManage({
    refDataContrato,
    votacaoSelecionada
}: IProps) {

    const [form] = Form.useForm();
    const refForm = useRef<any>(null);

    const refIdEditarVotacao = useRef<any>(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditarVotacao, setIsEditarVotacao] = useState(false);

    const refFuncaoLimparFiltrosString = useRef<any>(null);
    const refContagemDoFiltroMembros = useRef<Array<any>>([]);

    const [loadingPage, setLoadingPage] = useState(false);
    const [loadindTable, setLoadindTable] = useState(false);
    const [dataReq, setDataReq] = useState<Array<IReqAlternativas>>([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string | number>>([]);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);

    const [isModalImagemOpen, setIsModalImagemOpen] = useState(false);
    const [imagemModal, setImagemModal] = useState<any>(null);
    const [fotoAlternativa, setFotoAlternativa] = useState<any>('');

    const getAlternativas = useCallback(() => {
        const requestAsync = async () => {
            try {
                setLoadindTable(true);

                if (votacaoSelecionada) {

                    let { data } = await axios.get(
                        urlEntidadeVotacao(refDataContrato.current?.contrato_entidade.id) +
                        "/" + votacaoSelecionada.id + "/alternativa"
                        ,
                        { headers: { Authorization: "Bearer " + getToken() } }
                    );

                    setDataReq(data);
                }

                setLoadindTable(false);
            } catch (error) {
                setLoadindTable(false);

                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        return requestAsync();
    }, [votacaoSelecionada]);

    useEffect(() => {

        const buscaDadosIniciais = async () => {
            try {

                getAlternativas();

                setLoadingPage(false);
            } catch (error) {
                setLoadingPage(false);
                notification.error({
                    message: "Erro",
                    description: "Não foi possivel buscar dados!",
                });
            }
        };

        buscaDadosIniciais();

    }, [votacaoSelecionada]);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.id)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.id
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.id]);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.id);
                setSelectedRowKeys(teste);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    // const onSelectChange = useCallback((selectedRowKeys: any): void => {
    const onSelectChange = useCallback((selectedRowKeys: Array<string | number>, selectedRows: Array<any>): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);


    const rowSelection: any = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: "Foto",
            dataIndex: "imagem",
            width: "10%",
            render: (foto: any) => {
                return (
                    <img
                        src={
                            foto
                                ? foto
                                : "/default-avatar.png"
                        }
                        alt="Foto"
                        onClick={() => {
                            setImagemModal(foto);
                            setIsModalImagemOpen(true);
                        }}
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: 5,
                            cursor: "pointer",
                        }}
                    />
                );
            },
        },
        {
            title: "Título",
            dataIndex: "titulo",
            width: "20%",
            sorter: (a: any, b: any) => a?.titulo?.localeCompare(b?.titulo),
            ...useGetColumnSearchProps("titulo", refFuncaoLimparFiltrosString),
            showSorterTooltip: false,
        },
        {
            title: "Sub título",
            dataIndex: "subTitulo",
            width: "20%",
            sorter: (a: any, b: any) => a?.titulo?.localeCompare(b?.titulo),
            ...useGetColumnSearchProps("titulo", refFuncaoLimparFiltrosString),
            showSorterTooltip: false,
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            width: "20%",
            sorter: (a: any, b: any) => a?.descricao?.localeCompare(b?.descricao),
            ...useGetColumnSearchProps("descricao", refFuncaoLimparFiltrosString),
            showSorterTooltip: false,
            render: (taxa_de_juros: any) => {

                return (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Text
                            style={{ marginRight: 4 }}
                        >
                            {taxa_de_juros?.length > 25 ? taxa_de_juros.substring(0, 25) + '...' : taxa_de_juros}
                        </Text>
                        <Popover style={{ height: 80 }} overlayClassName="popOverlayTaxaDeJuros_vchvbqweyuv" content={taxa_de_juros} trigger="click">
                            <Button size="small"><PlusCircleOutlined /></Button>
                        </Popover>
                    </div>

                )
            },
        },
        {
            title: "Criado em",
            dataIndex: "created_at",
            width: "15%",
            showSorterTooltip: false,
            sorter: (a: any, b: any) =>
                a.created_at.localeCompare(b.created_at),
            render: (criado: any) => moment(criado).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ações",
            dataIndex: "id",
            width: "10%",
            render: (id: any, record: any) => (
                <span>
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        size="small"
                        onClick={(e) => {
                            e?.stopPropagation();

                            setLoadingPage(true);
                            setIsEditarVotacao(true);
                            refIdEditarVotacao.current = id

                            setFotoAlternativa(record?.imagem);

                            handleShowModal();

                            setTimeout(() => {

                                // Carrega os dados da noticia com block
                                refForm.current?.setFieldsValue({
                                    descricao: record.descricao,
                                    subTitulo: record.subTitulo,
                                    titulo: record.titulo,
                                    imagem: record.imagem,
                                });

                                setLoadingPage(false)
                            }, 1000);


                        }}
                    >
                        <EditOutlined />
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Deseja realmente excluir?"
                        onConfirm={(e) => {
                            e?.stopPropagation();

                            excluirAlternativa(id);
                        }}
                        onCancel={(e) => {
                            e?.stopPropagation();
                        }}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button
                            style={{
                                borderRadius: 5,
                                margin: 1,
                            }}
                            type="primary"
                            danger
                            size="small"
                            onClick={(e) => {
                                e?.stopPropagation();
                            }}
                        >
                            <BiTrash />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    const handleCancelModal = useCallback(() => {
        refForm.current?.resetFields();

        refIdEditarVotacao.current = null
        setFotoAlternativa('')

        setIsModalOpen(false);
    }, [])

    const handleShowModal = useCallback(() => {
        setIsModalOpen(true);
    }, [])

    const onClickBotaoSalvarModal = useCallback((): void => {

        const requestAsync = async (obj: any) => {
            try {
                setLoadingPage(true);
                setLoadindTable(true);

                if (votacaoSelecionada) {

                    await axios.post(
                        urlEntidadeVotacao(refDataContrato.current?.contrato_entidade.id) +
                        "/" + votacaoSelecionada.id + "/alternativa"
                        ,
                        obj,
                        {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                                "Content-Type": "multipart/form-data",
                            }
                        }
                    );

                    getAlternativas();

                    setLoadingPage(false);
                    setLoadindTable(false);

                    handleCancelModal();
                }

            } catch (error) {
                setLoadingPage(false);
                setLoadindTable(false);

                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };



        refForm.current
            .validateFields()
            .then((values: any) => {

                const data = new FormData();
                if (values.titulo) data.append("titulo", values.titulo);
                if (values.subTitulo) data.append("subTitulo", values.subTitulo);
                if (values.descricao) data.append("descricao", values.descricao);

                if (values.imagem) {
                    data.append("imagem", values.imagem);
                } else {
                    notification.error({
                        message: "Erro",
                        description: "Selecione uma imagem!",
                    });
                    return;
                }

                requestAsync(data);

            })
            .catch((errorInfo: any) => {

                setLoadindTable(false);
                setLoadingPage(false);
                const fieldWithError = errorInfo.errorFields[0].name[0];
                refForm.current.getFieldInstance(fieldWithError).focus();

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });

            });

    }, [votacaoSelecionada]);

    const onClickBotaoEditarModal = useCallback((): void => {

        const requestAsync = async (obj: any) => {
            try {
                if (votacaoSelecionada) {

                    setLoadingPage(true);
                    setLoadindTable(true);

                    await axios.put(
                        urlEntidadeVotacao(refDataContrato.current?.contrato_entidade.id) +
                        "/" + votacaoSelecionada.id + "/alternativa" +
                        '/' + refIdEditarVotacao.current
                        ,
                        obj,
                        {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    );

                    getAlternativas();

                    setLoadingPage(false);
                    setLoadindTable(false);

                    handleCancelModal();
                }
            } catch (error) {

                setLoadingPage(false);
                setLoadindTable(false);

                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };


        refForm.current
            .validateFields()
            .then((values: any) => {

                const data = new FormData();
                if (values.titulo) data.append("titulo", values.titulo);
                if (values.subTitulo) data.append("subTitulo", values.subTitulo);
                if (values.descricao) data.append("descricao", values.descricao);
                if (values.imagem) {
                    data.append("imagem", values.imagem);
                } else {
                    notification.error({
                        message: "Erro",
                        description: "Selecione uma imagem!",
                    });
                    return;
                }

                requestAsync(data);

            })
            .catch((errorInfo: any) => {

                setLoadingPage(false);
                const fieldWithError = errorInfo.errorFields[0].name[0];
                refForm.current.getFieldInstance(fieldWithError).focus();

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });

            });

    }, [votacaoSelecionada]);


    const excluirAlternativa = useCallback((id: any): void => {

        const requestAsync = async () => {
            try {
                if (votacaoSelecionada) {

                    setLoadingPage(true);
                    setLoadindTable(true);

                    await axios.delete(
                        urlEntidadeVotacao(refDataContrato.current?.contrato_entidade.id) +
                        "/" + votacaoSelecionada.id + "/alternativa" + '/' + id
                        ,
                        { headers: { Authorization: "Bearer " + getToken() } }
                    );

                    getAlternativas();
                }

            } catch (error) {

                setLoadingPage(false);
                setLoadindTable(false);

                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };

        requestAsync();

    }, [votacaoSelecionada]);

    const handleImagemCancel = useCallback(() => {
        setIsModalImagemOpen(false);
    }, []);

    const beforeUploadImgAlternativa = useCallback(async (file): Promise<any> => {
        const isJpgOrPng =
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error("Somente arquivos [png, jpg, jpeg] são suportados!");
            return;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Imagem não pode ser maior que 2MB!");
            return;
        }

        //Pega proporção da imagem
        const proporcaoImagem = await getProporcaoImagem(file);

        if (proporcaoImagem.width !== proporcaoImagem.height) {
            message.error("Imagem deve ser quadrada!");
            return;
        }

        refForm.current?.setFieldsValue({
            imagem: file,
        });

        setFotoAlternativa(file);
    }, []);

    const getProporcaoImagem = useCallback((file): Promise<any> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const { width, height } = img;
                resolve({ width, height });
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        });
    }, []);

    return {
        loadindTable,
        refContagemDoFiltroMembros,
        dataReq,
        columns,
        form,
        isModalOpen,
        handleCancelModal,
        refForm,
        loadingPage,
        onClickBotaoSalvarModal,
        onClickBotaoEditarModal,
        isEditarVotacao,
        setIsEditarVotacao,
        handleShowModal,
        rowSelection,
        onclickTable,
        hasSelectedEdit,
        selectedRowKeys,
        isModalImagemOpen,
        handleImagemCancel,
        imagemModal,
        beforeUploadImgAlternativa,
        fotoAlternativa
    }
}
