import { Button, Col, Form, Input, Modal, Row, Spin, Typography, Upload } from "antd";
import { colAllInputs, colFoto, colInputs } from "./GridStyle";
import ImgCrop from "antd-img-crop";

const { Text } = Typography;
export const ModalFormAlternativas = ({
    isModalOpen,
    handleCancelModal,
    form,
    refForm,
    onClickBotaoSalvarModal,
    onClickBotaoEditarModal,
    loadingPage,
    isEditarVotacao,

    beforeUploadImgAlternativa,
    fotoAlternativa
}: any) => {

    return (
        <Modal
            title={isEditarVotacao ? "Editar alternativa" : "Adicionar alternativa"}
            width={1000}
            footer={false}
            visible={isModalOpen}
            onOk={handleCancelModal}
            onCancel={handleCancelModal}
            destroyOnClose
        >
            <Spin spinning={loadingPage}>
                <Form
                    form={form}
                    ref={refForm}
                    name="formAlternativa"
                    layout="vertical"
                    onFinishFailed={() => { }}
                >
                    <Row>
                        <Col {...colFoto}>

                            <Row>
                                <Col hidden span={24}>
                                    <Form.Item name="imagem" label="Foto">
                                        <Input placeholder="foto" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    {/* Adicionar imagem do membro */}
                                    <Form.Item
                                        name="fotoAlternativaItem"
                                        label="Foto"
                                        style={{
                                            marginBottom: 0
                                        }}
                                    >
                                        <ImgCrop
                                            modalOk="Concluído"
                                            modalCancel="Cancelar"
                                            modalTitle="Editar"
                                        >
                                            <Upload
                                                fileList={[fotoAlternativa]}
                                                listType="picture-card"
                                                // className="avatar-uploader"
                                                style={{
                                                    width: "100%",
                                                    height: 108,
                                                    borderRadius: 10,
                                                    marginTop: 15,
                                                }}
                                                showUploadList={false}
                                                // customRequest={()}

                                                beforeUpload={(file) => {
                                                    fotoAlternativa = file;
                                                    beforeUploadImgAlternativa(file);
                                                    return false;
                                                }}

                                            >
                                                <img
                                                    src={
                                                        fotoAlternativa ?
                                                            fotoAlternativa?.uid ? URL.createObjectURL(fotoAlternativa) : fotoAlternativa
                                                            : "/default-avatar.png"
                                                    }
                                                    alt={fotoAlternativa}
                                                    style={{
                                                        width: 108,
                                                        height: 108,
                                                        marginTop: 0,
                                                        borderRadius: 10,
                                                    }}
                                                    className="alternativa-upload"

                                                />
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                    {/* texto secondary clique para adicionar uma foto */}
                                    <Text style={{ fontSize: 11, width: 108, textAlign: 'center' }}>
                                        Clique para adicionar uma foto
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col {...colAllInputs}>
                            <Row>
                                {/* <Col hidden {...inputNome}>
                        <Form.Item
                            name="idMembro"
                            label="id"
                            rules={[
                                {
                                    required: false,
                                    message: "Por favor preencha o id!",
                                },
                            ]}
                        >
                            <Input placeholder="id" disabled />
                        </Form.Item>
                    </Col> */}
                                <Col {...colInputs}>
                                    <Form.Item
                                        name="titulo"
                                        label="Título"
                                        style={{ margin: 5 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Por favor, digite o título.",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Eleições" />
                                    </Form.Item>
                                </Col>
                                <Col {...colInputs}>
                                    <Form.Item
                                        name="subTitulo"
                                        label="Sub título"
                                        style={{ margin: 5 }}
                                        rules={[
                                            {
                                                required: false,
                                                message: "Por favor, digite o sub título.",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Eleições" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="descricao"
                                        label="Descrição"
                                        style={{ margin: 5 }}
                                        rules={[
                                            {
                                                required: false,
                                                message:
                                                    "Por favor, digite a descrição.",
                                            },
                                        ]}
                                    >
                                        <Input.TextArea
                                            style={{ maxHeight: 300, height: 150 }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: "end" }}>
                            <div
                                style={{ textAlign: "end", marginRight: 5, marginTop: 10 }}
                            >

                                <Button
                                    style={{ marginRight: 10 }}
                                    onClick={handleCancelModal}
                                >
                                    Cancelar
                                </Button>
                                {isEditarVotacao ? (
                                    <Button
                                        type="primary"
                                        loading={loadingPage}
                                        onClick={onClickBotaoEditarModal}
                                    >
                                        Atualizar
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        loading={loadingPage}
                                        onClick={onClickBotaoSalvarModal}
                                    >
                                        Salvar
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}
