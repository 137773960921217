import React, { useEffect, useCallback, useState, useRef } from 'react';
import 'antd/dist/antd.css';
import { Layout, Menu, Button, Dropdown, Drawer, Radio, MenuProps, Typography, Avatar } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    SolutionOutlined,
    LogoutOutlined,
    MobileOutlined,
    MessageOutlined,
    TeamOutlined,
    MenuOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as GlobalActions from '../../store/modules/global/actions';
import * as LoginActions from '../../store/modules/login/actions';
import { useHistory } from 'react-router-dom';
import { stateGlobal } from '../../interfaces/Global';
import Icon from '@ant-design/icons';
import { FaSitemap, FaCog, FaUnlockAlt, FaTv, FaRegListAlt, FaListAlt, FaUserAlt, FaLockOpen } from 'react-icons/fa';
import { ReactComponent as FaPenSquare } from '../../assets/imagsIconesModulos/pen-to-square.svg';

import './style.css';

// #@Natal
// // @ts-ignore
// import GIFLogoDark from '../../assets/GIF_LOGO_NATAL_DARK_PAINEL.gif';
// // @ts-ignore
// import GIFLogo from '../../assets/GIF_LOGO_NATAL_BLUE_PAINEL.gif';

// @ts-ignore
import ImagemGovfacil from './../../assets/govfacil_logo.png';
// @ts-ignore
import ImagemLogo from '../../assets/logo.png';
// @ts-ignore
import ImagemLogoDark from '../../assets/logo_dark.png';
// @ts-ignore
import ImagemIcone from '../../assets/icone.png';
import { getUsuario } from '../../services/token/usuario';

const { Text } = Typography;

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const IconHand = () => <Icon style={{ fontSize: 17 }} component={FaSitemap} ></Icon>
const IconCogs = (props: any) => <Icon {...props} component={FaCog} ></Icon>
const IconTv = (props: any) => <Icon {...props} component={FaTv} ></Icon>
const UnlokProcessoJuridico = (props: any) => <Icon {...props} component={FaUnlockAlt} ></Icon>
const IconFaClipboardList = (props: any) => <Icon {...props} component={FaRegListAlt} ></Icon>
const IconFaPenSquare = (props: any) => <Icon {...props} component={FaPenSquare} ></Icon>

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    permissao: boolean,
    classNamePopUpDrawer: string,
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    let popupClassName = classNamePopUpDrawer;
    return {
        key,
        popupClassName,
        icon,
        children,
        label,
        type,
        permissao: permissao.toString()
    } as MenuItem;
}

const LayoutDashboard: React.FC = ({ children }) => {

    const dispatch = useDispatch();
    const propsState = useSelector((state: stateGlobal) => state.global);
    const history = useHistory();

    const [collapsed, setCollapsed] = useState(false);
    const [collapsedWidth, setCollapsedWidth] = useState<number>(77);
    const [visibleDrawer, setvisibleDrawer] = useState(false);
    const [visibleDrawerColor, setVisibleDrawerColor] = useState(false);

    const [abrirMenuLateral, setAbrirMenuLateral] = useState<Array<string>>(['']);
    const [nomeUsuarioLogado, setNomeUsuarioLogado] = useState('');

    const [itemsMenuConfiguracaoState, setItemsMenuConfiguracaoState] = useState<MenuProps['items']>([]);
    const refItemsMenuConfiguracaoState = useRef<MenuProps['items']>([]);

    const [itemsMenuPainelState, setItemsMenuPainelState] = useState<MenuItem[]>([]);
    const refItemsMenuPainelState = useRef<MenuItem[]>([]);

    const onCloseDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(false);
    }, []);

    const onAbrirDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(true);
    }, []);

    const onchangeRadioTheme = useCallback((value: any): void => {

        dispatch(GlobalActions.updateTheme(value.target.value));

        history.go(0);
    }, []);

    useEffect(() => {
        let arrayUrl = window.location.pathname.split("/");

        switch (window.location.pathname) {
            case "/dashboard":
                dispatch(GlobalActions.updatePositionMenu('1'));
                setAbrirMenuLateral([''])
                break;
            case "/atendimentos":
                dispatch(GlobalActions.updatePositionMenu('2'));
                setAbrirMenuLateral([''])
                break;
            case "/atendimentos/adicionar":
                dispatch(GlobalActions.updatePositionMenu('2'));
                setAbrirMenuLateral([''])
                break;
            case "/atendimentos/editar/" + arrayUrl[3]:
                dispatch(GlobalActions.updatePositionMenu('2'));
                setAbrirMenuLateral([''])
                break;
            case "/outros-atendimentos":
                dispatch(GlobalActions.updatePositionMenu('2outrosAtendimentos'));
                setAbrirMenuLateral([''])
                break;
            case "/outros-atendimentos/adicionar":
                dispatch(GlobalActions.updatePositionMenu('2outrosAtendimentos'));
                setAbrirMenuLateral([''])
                break;
            case "/outros-atendimentos/editar/" + arrayUrl[3]:
                dispatch(GlobalActions.updatePositionMenu('2outrosAtendimentos'));
                setAbrirMenuLateral([''])
                break;
            case "/usuarios":
                dispatch(GlobalActions.updatePositionMenu('3'));
                setAbrirMenuLateral([''])
                break;
            case "/usuarios/adicionar":
                dispatch(GlobalActions.updatePositionMenu('3'));
                setAbrirMenuLateral([''])
                break;
            case "/contrato":
                dispatch(GlobalActions.updatePositionMenu('4'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/contrato/adicionar":
                dispatch(GlobalActions.updatePositionMenu('4'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/cidadesUser":
                dispatch(GlobalActions.updatePositionMenu('5'));
                setAbrirMenuLateral([''])
                break;
            case "/cidadesUser/adicionar":
                dispatch(GlobalActions.updatePositionMenu('5'));
                setAbrirMenuLateral([''])
                break;
            case "/associacao":
                dispatch(GlobalActions.updatePositionMenu('6'));
                setAbrirMenuLateral([''])
                break;
            case "/associacao/adicionar":
                dispatch(GlobalActions.updatePositionMenu('6'));
                setAbrirMenuLateral([''])
                break;
            case "/liscodigosms":
                dispatch(GlobalActions.updatePositionMenu('7'));
                setAbrirMenuLateral([''])
                break;
            case "/listcaptacaousuario":
                dispatch(GlobalActions.updatePositionMenu('8'));
                setAbrirMenuLateral([''])
                break;
            case "/listcaptacaousuario/addobrigacao":
                dispatch(GlobalActions.updatePositionMenu('8'));
                setAbrirMenuLateral([''])
                break;
            case "/mensagens":
                dispatch(GlobalActions.updatePositionMenu('9'));
                setAbrirMenuLateral([''])
                break;
            case "/mensagens/add":
                dispatch(GlobalActions.updatePositionMenu('9'));
                setAbrirMenuLateral([''])
                break;
            case "/relatorios/dispositivosativos":
                dispatch(GlobalActions.updatePositionMenu('10'));
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case "/contrato/municipios":
                dispatch(GlobalActions.updatePositionMenu('11'));
                setAbrirMenuLateral([''])
                break;
            case "/contrato/modulo/" + arrayUrl[3]:
                dispatch(GlobalActions.updatePositionMenu('12'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/pesquisa-satisfacao":
                dispatch(GlobalActions.updatePositionMenu('pesquisaDeSatisfacao'));
                setAbrirMenuLateral([''])
                break;
            case "/pesquisa-satisfacao/criar":
                dispatch(GlobalActions.updatePositionMenu('pesquisaDeSatisfacao'));
                setAbrirMenuLateral([''])
                break;
            case "/contrato/entidades":
                dispatch(GlobalActions.updatePositionMenu('12'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/contrato/entidades/gestor/perfil":
                dispatch(GlobalActions.updatePositionMenu('13'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/contrato/entidades/gestor/permissao":
                dispatch(GlobalActions.updatePositionMenu('14'));
                setAbrirMenuLateral(['subContratos'])
                break;
            case "/relatorios/pendencias-municipios-por-estado":
                dispatch(GlobalActions.updatePositionMenu('RelatorioPendenciasMunicipios'));
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case "/relatorios/acessos":
                dispatch(GlobalActions.updatePositionMenu('RelAcessos'));
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case "/integracoes":
                dispatch(GlobalActions.updatePositionMenu('integracoes'));
                setAbrirMenuLateral([''])
                break;
            case '/integracoes/' + arrayUrl[2]:
                dispatch(GlobalActions.updatePositionMenu('integracoes'));
                setAbrirMenuLateral([''])
            case '/integracoes/sicon-personalizacao/' + arrayUrl[3]:
                dispatch(GlobalActions.updatePositionMenu('integracoes'));
                setAbrirMenuLateral([''])
                break;

            default:
                dispatch(GlobalActions.updatePositionMenu(''));
                break;
        }
    }, [window.location.pathname]);


    useEffect(() => {

        let dataUsuario = getUsuario();
        if (dataUsuario) {
            let arrayUrl = dataUsuario.nome.split(" ")
            setNomeUsuarioLogado(arrayUrl[0])
        }

        let isDrawerOpned = '';

        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                isDrawerOpned = 'dspclassMenuPopUpDraweer';
                setCollapsedWidth(0);
            }
        }


        if (refItemsMenuConfiguracaoState.current && refItemsMenuConfiguracaoState.current.length === 0) {

            const itemsMenuConfiguracao: MenuProps['items'] = [
                getItem(true, isDrawerOpned, 'Configurações', '3', <IconCogs />),
                getItem(true, isDrawerOpned, 'Tema', '4', <IconTv />),
                getItem(true, isDrawerOpned, 'Sair', '5', <LogoutOutlined />),
            ];

            const menuConfig = itemsMenuConfiguracao.filter((element: any) => element?.permissao === 'true')

            setItemsMenuConfiguracaoState(menuConfig)
        }

        if (refItemsMenuPainelState.current && refItemsMenuPainelState.current.length === 0) {


            const itemsMenuPainel: MenuItem[] = [
                getItem(true, isDrawerOpned, 'Atendimentos', '2', <TeamOutlined />),
                getItem(true, isDrawerOpned, 'Outros Atendimentos', '2outrosAtendimentos', <TeamOutlined />),
                getItem(true, isDrawerOpned, 'Usuários', '3', <UserOutlined />),
                getItem(true, isDrawerOpned, 'Contratos', 'subContratos', <SolutionOutlined />, [
                    getItem(true, isDrawerOpned, 'Listagem de Contratos', '4', <FaListAlt />),
                    getItem(true, isDrawerOpned, 'Entidades', '12', <FaListAlt />),
                    getItem(true, isDrawerOpned, 'Painel Gestor', 'sub3', <FaListAlt />, [
                        getItem(true, isDrawerOpned, 'Perfis de Usuários', '13', <FaUserAlt />),
                        getItem(true, isDrawerOpned, 'Permissões de Usuários', '14', <FaLockOpen />),
                    ]),
                ]),
                getItem(true, isDrawerOpned, 'Municípios por Contrato', '11', <IconFaClipboardList />),
                getItem(true, isDrawerOpned, 'Processos Jurídicos (Senha)', '5', <UnlokProcessoJuridico />),
                getItem(true, isDrawerOpned, 'Relatórios', 'subRelatorios', <MenuOutlined />, [
                    getItem(true, isDrawerOpned, 'Acessos', 'RelAcessos', <FileTextOutlined />),
                    getItem(true, isDrawerOpned, 'Dispositivos Ativos', '10', <MobileOutlined />),
                    getItem(true, isDrawerOpned, 'Pendências de Municípios', 'RelatorioPendenciasMunicipios', <FileTextOutlined />),
                ]),
                getItem(true, isDrawerOpned, 'Mensagens', '9', <MessageOutlined />),
                getItem(true, isDrawerOpned, 'Códigos Autenticação', '7', <MobileOutlined />),
                getItem(true, isDrawerOpned, 'Pesquisa de Satisfação', 'pesquisaDeSatisfacao', <IconFaPenSquare />),
                getItem(true, isDrawerOpned, 'Associações', '6', <IconHand />),
                getItem(true, isDrawerOpned, 'Integrações', 'integracoes', <IconHand />),
            ];

            //permissao para itens do painel
            const menuPainel = itemsMenuPainel.filter((element: any) => element?.permissao === 'true')

            //permissao sub para itens do painel (collapse)
            menuPainel.map((element: any) => {
                if (element?.children && element?.children.length > 0) {
                    const subPermissoions = element?.children.filter((subElement: any) => subElement?.permissao === 'true')
                    element.children = subPermissoions
                    return element
                }
                return element
            })


            setItemsMenuPainelState(menuPainel)

        }

    }, []);


    const clickNavigateMenuLateral = useCallback((rota: string): void => {
        history.push(rota);
        setvisibleDrawer(false);
    }, []);

    const clickMenuPrinciopal = useCallback(({ key }: any): void => {
        dispatch(GlobalActions.updatePositionMenu(key));

        switch (key) {
            case '1':
                clickNavigateMenuLateral("/dashboard");
                setAbrirMenuLateral([''])
                break;
            case '2':
                clickNavigateMenuLateral('/atendimentos');
                setAbrirMenuLateral([''])
                break;
            case '2outrosAtendimentos':
                clickNavigateMenuLateral('/outros-atendimentos');
                setAbrirMenuLateral([''])
                break;
            case '3':
                let arrayUrl = window.location.pathname.split("/");

                if (arrayUrl[1] === 'usuarios' && !arrayUrl[2]) {

                } else {

                    let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:ListUsersContrato');
                    let localUserData: any = null;
                    if (typeof dadosLocalStorage == 'string') {
                        localUserData = JSON.parse(dadosLocalStorage);
                    }

                    if (localUserData) {
                        localStorage.removeItem('@GovFacil:ListUsersContrato');
                    }
                    clickNavigateMenuLateral('/usuarios');
                    setAbrirMenuLateral([''])
                }
                setvisibleDrawer(false);
                break;
            case '4':
                clickNavigateMenuLateral('/contrato');
                setAbrirMenuLateral(['subContratos'])
                break;
            case '5':
                clickNavigateMenuLateral('/cidadesUser');
                setAbrirMenuLateral([''])
                break;
            case '6':
                clickNavigateMenuLateral('/associacao');
                setAbrirMenuLateral([''])
                break;
            case '7':
                clickNavigateMenuLateral('/liscodigosms');
                setAbrirMenuLateral([''])
                break;
                // case '8':
                //     clickNavigateMenuLateral('/listcaptacaousuario');
                setAbrirMenuLateral([''])
            //     break;
            case '9':
                clickNavigateMenuLateral('/mensagens');
                setAbrirMenuLateral([''])
                break;
            case '10':
                clickNavigateMenuLateral('/relatorios/dispositivosativos');
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case '11':
                clickNavigateMenuLateral('/contrato/municipios');
                setAbrirMenuLateral([''])
                break;
            case 'pesquisaDeSatisfacao':
                clickNavigateMenuLateral('/pesquisa-satisfacao');
                setAbrirMenuLateral([''])
                break;
            case '12':
                clickNavigateMenuLateral('/contrato/entidades');
                setAbrirMenuLateral(['subContratos'])
                break;
            case '13':
                clickNavigateMenuLateral('/contrato/entidades/gestor/perfil');
                setAbrirMenuLateral(['subContratos'])
                break;
            case '14':
                clickNavigateMenuLateral('/contrato/entidades/gestor/permissao');
                setAbrirMenuLateral(['subContratos'])
                break;
            case 'RelatorioPendenciasMunicipios':
                clickNavigateMenuLateral('/relatorios/pendencias-municipios-por-estado');
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case 'RelAcessos':
                clickNavigateMenuLateral('/relatorios/acessos');
                setAbrirMenuLateral(['subRelatorios'])
                break;
            case 'integracoes':
                let dadosLocalStorage: string | null = localStorage.getItem('@GovFacil:ListInteracoesByMunicipio');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                }

                if (localUserData) {
                    localStorage.removeItem('@GovFacil:ListInteracoesByMunicipio');
                }
                clickNavigateMenuLateral('/integracoes');
                setAbrirMenuLateral([''])
                break;
            default:
                break;
        }

    }, []);

    const toggle = useCallback((): void => {
        if (collapsedWidth === 0) {
            setvisibleDrawer(!visibleDrawer);
        } else {
            setCollapsed(!collapsed);
        }
    }, [collapsedWidth, visibleDrawer, collapsed]);

    const onCollapse = useCallback((collapsedVal): void => {
        if (!collapsed) {
            setCollapsed(collapsedVal);
            setCollapsedWidth(77);
        } else {
            setCollapsed(collapsedVal);
            setCollapsedWidth(0);
        }
    }, [collapsed, collapsedWidth]);

    const onCloseDrawer = useCallback((): void => {
        setvisibleDrawer(false);
    }, []);

    const clickMenuDireitoConfiguracoes = useCallback((): void => {
        history.push("/configuracoesdosistema");
    }, []);

    const clickMenuDireitoSair = useCallback((): void => {
        history.push("/");
        localStorage.removeItem('@GovFacil:token');
        dispatch(LoginActions.loginAlterSingOut());
    }, []);

    const onClickMenuConfiguracoes = useCallback((e: any): void => {
        switch (e.key) {
            case '3':
                clickMenuDireitoConfiguracoes();
                break;
            case '4':
                onAbrirDrawerColor();
                break;
            case '5':
                clickMenuDireitoSair();
                break;

            default:
                break;
        }

    }, []);

    return (
        <>
            <Drawer
                placement="left"
                closable={false}
                onClose={onCloseDrawer}
                open={visibleDrawer}
                key="left"
                style={{ padding: 0 }}
            // bodyStyle={{background:'red'}}
            >
                <img className="logoimgMenuResponsive" style={{marginBottom: 10, marginTop: -10}} src={ImagemLogo}></img>
                <Menu mode="inline"
                    onClick={position => { clickMenuPrinciopal(position) }}
                    defaultSelectedKeys={[propsState.positionMenu]}
                    selectedKeys={[propsState.positionMenu]}
                    forceSubMenuRender={false}
                    triggerSubMenuAction="click"
                    // style={{ width: 262, marginLeft: -30, padding: 0 }}
                    onOpenChange={(openKeys) => setAbrirMenuLateral(openKeys)}
                    className="DraerwMenuPrinmcipal"
                    // theme="dark"
                    items={itemsMenuPainelState}
                    style={{
                        marginLeft: -24,
                        marginRight: -24,
                        // padding: 0
                    }}
                // openKeys={abrirMenuLateral}
                />
            </Drawer>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    width={280}
                    collapsedWidth={collapsedWidth}
                    breakpoint="md"
                    onBreakpoint={broken => {
                        onCollapse(broken);
                    }}
                    collapsed={collapsed}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        scrollBehavior: 'smooth'
                    }}
                >
                    {/* <div className="logo" /> */}
                    {collapsed ?
                        <img className="iconePGimg" src={ImagemIcone}></img>
                        : <img className="logoimg" src={
                            ImagemLogoDark
                            // propsState.theme === 'light' ? GIFLogo : GIFLogoDark
                        }></img>}
                    <Menu theme="dark" mode="inline"
                        onClick={position => { clickMenuPrinciopal(position) }}
                        defaultSelectedKeys={[propsState.positionMenu]}
                        selectedKeys={[propsState.positionMenu]}
                        style={{}}
                        items={itemsMenuPainelState}
                        openKeys={abrirMenuLateral}
                        onOpenChange={(openKeys) => setAbrirMenuLateral(openKeys)}
                    />

                </Sider>
                <Layout className={collapsed ? "siteLayout2" : "siteLayout"}>
                    <Header className="site-layout-sub-header-background"
                        style={{
                            position: 'fixed',
                            zIndex: 2,
                            padding: 0,
                            width: collapsed ? 'calc(100% - 75px)' : 'calc(100% - 280px)'
                        }}
                    >
                        <div style={{ float: "left" }}>
                            {React.createElement(
                                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                                {
                                    className: "trigger",
                                    onClick: toggle
                                }
                            )}
                        </div>

                        <Dropdown
                            // onClick={handleButtonClick}
                            trigger={['click']}
                            className="dropDownMenu"
                            menu={{
                                items: itemsMenuConfiguracaoState,
                                onClick: onClickMenuConfiguracoes,
                            }}
                        >
                            <Button
                                style={{
                                    // marginRight: 10,
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                                type="text">
                                {
                                    collapsedWidth !== 0 &&
                                    <Text
                                        style={{
                                            marginRight: '6px'
                                        }}
                                    >{nomeUsuarioLogado}</Text>
                                }
                                <Avatar size={28} icon={<UserOutlined />} />
                            </Button>
                        </Dropdown>

                    </Header>
                    <Content className="site-layout-background" >
                        {children}
                    </Content>
                    <Footer
                        style={{ textAlign: 'center', marginTop: -10, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}
                    >
                        Desenvolvido por <img style={{
                            width: 90,
                            marginLeft: 5
                        }} src={ImagemGovfacil} />
                    </Footer>
                </Layout>
            </Layout>
            {/* Drawer para alterar cor do sistema */}
            <Drawer
                title="Tema do sistema"
                placement="right"
                closable={false}
                onClose={onCloseDrawerColor}
                open={visibleDrawerColor}
            >
                <Radio.Group onChange={onchangeRadioTheme} defaultValue={propsState.theme} size="large">
                    <Radio.Button value="light">Light</Radio.Button>
                    <Radio.Button value="dark">Dark</Radio.Button>
                    {/* <Radio.Button value="c">Beijing</Radio.Button> */}
                </Radio.Group>
            </Drawer>
        </>
    );
};

export default LayoutDashboard;
