import { Button, Col, Form, Input, Modal, Row, Select, Upload } from "antd";
import { urlMembroEntidadeFoto } from "../../../../../../services/request/urls";
import { inputNome } from "../GridStyle";
import ImgCrop from 'antd-img-crop';
import 'antd/dist/antd.css';


export const ModalFormMembro = ({ isModalMembroOpen, handleMembroCancel, formMembro, refFormMembro, beforeUploadMembro, onClickBotaoSalvarMembro, loadingPage, isEditarMembro, fotoMembro }: any) => {

    return (
        <Modal
                title={isEditarMembro ? "Editar Membro" : "Adicionar Membro"}
                width={1000}
                footer={[]}
                visible={isModalMembroOpen}
                onOk={handleMembroCancel}
                onCancel={handleMembroCancel}
            >
                <Form
                    form={formMembro}
                    ref={refFormMembro}
                    name="formMembro"
                    layout="vertical"
                    onFinishFailed={() => {}}
                >
                    <Row gutter={16}>
                        <Col hidden {...inputNome}>
                            <Form.Item
                                name="idMembro"
                                label="id"
                                rules={[
                                    {
                                        required: false,
                                        message: "Por favor preencha o id!",
                                    },
                                ]}
                            >
                                <Input placeholder="id" disabled />
                            </Form.Item>
                            <Form.Item name="fotoMembro" label="Foto">
                                <Input placeholder="foto" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={19}>
                            <Form.Item
                                name="nomeMembro"
                                label="Nome"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor preencha o nome!",
                                    },
                                ]}
                            >
                                <Input placeholder="Nome" />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col span={10}>
                                    <Form.Item
                                        name="cargoMembro"
                                        label="Cargo"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Por favor preencha o cargo!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Cargo" />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        name="mandatoMembro"
                                        label="Mandato"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Por favor preencha o mandato!",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Mandato" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="ordemMembro"
                                        label="Ordem"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Por favor preencha a ordem!",
                                            },
                                        ]}
                                    >
                                        <Input type="number" placeholder="Ordem" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={1} />
                        <Col span={4}>
                            {/* Adicionar imagem do membro */}
                            <Form.Item name="fotoMembroItem" label="Foto"
                            >
                                <ImgCrop
                                    modalOk="Concluído"
                                    modalCancel="Cancelar"
                                    modalTitle="Editar"
                                    >
                                    <Upload
                                        fileList={[fotoMembro]}
                                        listType="picture-card"
                                        // className="avatar-uploader"
                                        style={{
                                            width: "100%",
                                            height: 108,
                                            borderRadius: 10,
                                            marginTop: 15,
                                        }}
                                        showUploadList={false}
                                        // customRequest={()}

                                        beforeUpload={(file) => {
                                            fotoMembro = file;
                                            beforeUploadMembro(file);
                                            return false;
                                        }}

                                    >
                                        <img
                                            src={
                                                fotoMembro !== " "
                                                    ? fotoMembro?.uid ? URL.createObjectURL(fotoMembro) : urlMembroEntidadeFoto + '/' + fotoMembro
                                                    : "/default-avatar.png"
                                            }
                                            alt={fotoMembro}
                                            style={{
                                                width: 108,
                                                height: 108,
                                                marginTop: 0,
                                                borderRadius: 10,
                                            }}
                                            className="membro-upload"

                                        />
                                    </Upload>
                                </ImgCrop>
                                {/* texto secondary clique para adicionar uma foto */}
                                    <p style={{fontSize: 11, width: 108, textAlign: 'center', color: '#00000099'}}>
                                        Clique para adicionar uma foto
                                    </p>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={18}>
                        <Col span={24}>
                            <Form.Item
                                name="descricaoMembro"
                                label="Descrição"
                            >
                                <Input.TextArea
                                    style={{ maxHeight: 150, height: 20 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: "end" }}>
                            <Button
                                style={{ marginRight: 10 }}
                                onClick={handleMembroCancel}
                            >
                                Cancelar
                            </Button>
                            {isEditarMembro ? (
                                <Button
                                    type="primary"
                                    loading={loadingPage}
                                    onClick={onClickBotaoSalvarMembro}
                                >
                                    Atualizar
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    loading={loadingPage}
                                    onClick={onClickBotaoSalvarMembro}
                                >
                                    Salvar
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Modal>
    );
}
