import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Divider, Form, notification, Popconfirm } from "antd";
import { useGetColumnSearchProps } from "../../../../../../hooks/FilterTable/stringCLearFiltros";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import { urlCargo, urlEntidadeVotacao } from "../../../../../../services/request/urls";
import axios from "axios";
import { getToken } from "../../../../../../services/token";
import { IReqVotacoes } from "./interfaces";
import { convertDataBrParaUS, convertDataEHoraBrParaUS } from "../../../../../../services/Conversores";
import { BiTrash } from "react-icons/bi";
import { isAfter } from "date-fns";

interface IProps {
    refDataContrato: any
}

export default function useVotacoesManage({
    refDataContrato
}: IProps) {

    const [form] = Form.useForm();
    const refForm = useRef<any>(null);

    const refIdEditarVotacao = useRef<any>(null);

    const [viewScreenAlternativas, setViewScreenAlternativas] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditarVotacao, setIsEditarVotacao] = useState(false);

    const [selectedDateInicio, setSelectedDateInicio] = useState<any>(null);
    const [selectedDateFim, setSelectedDateFim] = useState<any>(null);

    const refFuncaoLimparFiltrosString = useRef<any>(null);
    const refContagemDoFiltroMembros = useRef<Array<any>>([]);

    const [loadingPage, setLoadingPage] = useState(false);
    const [loadindTable, setLoadindTable] = useState(false);
    const [dataReq, setDataReq] = useState<Array<IReqVotacoes>>([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string | number>>([]);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);

    const refFormSelectCargos = useRef<any>(null);
    const refBotaoSeleCargos = useRef<any>(null);
    const [dadosCargosList, setDadosCargosList] = useState<Array<any>>([]);

    const [isModalOpenResultadoVotacoes, setIsModalOpenResultadoVotacoes] = useState(false);

    const getVotacoes = useCallback(() => {
        const requestAsync = async () => {
            try {
                setLoadindTable(true);

                let { data } = await axios.get(
                    urlEntidadeVotacao(refDataContrato.current?.contrato_entidade.id)
                    ,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );

                setDataReq(data);

                setLoadindTable(false);
            } catch (error) {
                setLoadindTable(false);

                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        return requestAsync();
    }, []);

    //setando dados das cidades no selected
    const getCargos = useCallback(() => {
        // setLoadingUsuariosSelected(true);
        const requestAsync = async () => {
            try {
                let result = await axios.get(urlCargo,
                    {
                        headers: { 'Authorization': 'Bearer ' + getToken() },
                        // cancelToken: signal.token
                    });
                setDadosCargosList(result.data);

                return true;

            } catch (error) {
                let msgErro: any = (error as Error);
                if (msgErro?.message != 'Requisicao cancelada!') {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Não foi possivel buscar Cargos!',
                    });
                }
                return false
            }
        }

        return requestAsync();

    }, []);

    const comonentDidMount = useEffect((): void => {

        const buscaDadosIniciais = async () => {
            try {

                getVotacoes();

                getCargos();

                setLoadingPage(false);
            } catch (error) {
                setLoadingPage(false);
                notification.error({
                    message: "Erro",
                    description: "Não foi possivel buscar dados!",
                });
            }
        };

        buscaDadosIniciais();

    }, []);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.id)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.id
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.id]);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.id);
                setSelectedRowKeys(teste);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    // const onSelectChange = useCallback((selectedRowKeys: any): void => {
    const onSelectChange = useCallback((selectedRowKeys: Array<string | number>, selectedRows: Array<any>): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);


    const rowSelection: any = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        // {
        //     title: "Foto",
        //     dataIndex: "foto",
        //     width: "10%",
        //     render: (foto: any) => {
        //         return (
        //             <img
        //                 src={
        //                     foto !== " "
        //                         ? urlMembroEntidadeFoto + "/" + foto
        //                         : "/default-avatar.png"
        //                 }
        //                 alt="Foto"
        //                 onClick={() => {
        //                     setImagemModal(urlMembroEntidadeFoto + "/" + foto);
        //                     setIsModalImagemOpen(true);
        //                 }}
        //                 style={{
        //                     width: 50,
        //                     height: 50,
        //                     borderRadius: 5,
        //                     cursor: "pointer",
        //                 }}
        //             />
        //         );
        //     },
        // },
        {
            title: "Título",
            dataIndex: "titulo",
            width: "20%",
            sorter: (a: any, b: any) => a?.titulo?.localeCompare(b?.titulo),
            ...useGetColumnSearchProps("titulo", refFuncaoLimparFiltrosString),
            showSorterTooltip: false,
        },
        {
            title: "Descrição",
            dataIndex: "descricao",
            width: "20%",
            sorter: (a: any, b: any) => a?.descricao?.localeCompare(b?.descricao),
            ...useGetColumnSearchProps("descricao", refFuncaoLimparFiltrosString),
            showSorterTooltip: false,
        },
        {
            title: "Data início",
            dataIndex: "dataInicio",
            width: "15%",
            showSorterTooltip: false,
            sorter: (a: any, b: any) =>
                a.dataInicio.localeCompare(b.dataInicio),
            render: (criado: any) => moment(criado).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Data fim",
            dataIndex: "dataFim",
            width: "15%",
            showSorterTooltip: false,
            sorter: (a: any, b: any) =>
                a.dataFim.localeCompare(b.dataFim),
            render: (criado: any) => moment(criado).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Criado em",
            dataIndex: "created_at",
            width: "15%",
            showSorterTooltip: false,
            sorter: (a: any, b: any) =>
                a.created_at.localeCompare(b.created_at),
            render: (criado: any) => moment(criado).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ações",
            dataIndex: "id",
            width: "10%",
            render: (id: any, record: any) => (
                <span>
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        size="small"
                        onClick={(e) => {
                            e?.stopPropagation();

                            setLoadingPage(true);
                            setIsEditarVotacao(true);
                            refIdEditarVotacao.current = id

                            handleShowModal();

                            const dtInicio = moment(record.dataInicio)
                            const dtFim = moment(record.dataFim)


                            setTimeout(() => {
                                setSelectedDateInicio(dtInicio)
                                setSelectedDateFim(dtFim)

                                // Carrega os dados da noticia com block
                                refForm.current?.setFieldsValue({
                                    dataFim: dtFim,
                                    dataInicio: dtInicio,
                                    descricao: record.descricao,
                                    titulo: record.titulo,
                                    cargosPermitidosIds: record.cargosPermitidos,
                                });

                                setLoadingPage(false)
                            }, 1000);


                        }}
                    >
                        <EditOutlined />
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Deseja realmente excluir?"
                        onConfirm={(e) => {
                            e?.stopPropagation();

                            excluirVotacao(id);
                        }}
                        onCancel={(e) => {
                            e?.stopPropagation();
                        }}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button
                            style={{
                                borderRadius: 5,
                                margin: 1,
                            }}
                            type="primary"
                            danger
                            size="small"
                            onClick={(e) => {
                                e?.stopPropagation();
                            }}
                        >
                            <BiTrash />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    const handleCancelModalResultadoVotacoes = useCallback(() => {

        setIsModalOpenResultadoVotacoes(false);
    }, [])

    const handleShowModalResultadoVotacoes = useCallback(() => {
        setIsModalOpenResultadoVotacoes(true);
    }, [])

    const handleCancelModal = useCallback(() => {
        refForm.current?.resetFields();
        setSelectedDateInicio(null)
        setSelectedDateFim(null)

        refIdEditarVotacao.current = null

        setIsModalOpen(false);
    }, [])

    const handleShowModal = useCallback(() => {
        setIsModalOpen(true);
    }, [])

    const onClickBotaoSalvarModal = useCallback((): void => {

        const requestAsync = async (obj: any) => {
            try {
                setLoadingPage(true);
                setLoadindTable(true);

                await axios.post(
                    urlEntidadeVotacao(refDataContrato.current?.contrato_entidade.id)
                    ,
                    obj,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );

                getVotacoes();

                setLoadingPage(false);
                setLoadindTable(false);

                handleCancelModal();
            } catch (error) {
                setLoadingPage(false);
                setLoadindTable(false);

                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };



        refForm.current
            .validateFields()
            .then((values: any) => {

                const dtIni = convertDataEHoraBrParaUS(values.dataInicio)
                const dtFim = convertDataEHoraBrParaUS(values.dataFim)

                // validar se a data inicial não poder maior que a data final
                const resultado = isAfter(new Date(dtIni)!, new Date(dtFim)!)

                if (resultado) {
                    notification.error({
                        message: 'Data inválida.',
                        description:
                            'A data inicial não pode ser posterior à data final.',
                    });
                    return
                }

                values.dataInicio = dtIni
                values.dataFim = dtFim

                requestAsync(values);

            })
            .catch((errorInfo: any) => {

                setLoadindTable(false);
                setLoadingPage(false);
                const fieldWithError = errorInfo.errorFields[0].name[0];
                refForm.current.getFieldInstance(fieldWithError).focus();

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });

            });

    }, []);

    const onClickBotaoEditarModal = useCallback((): void => {

        const requestAsync = async (obj: any) => {
            try {
                setLoadingPage(true);
                setLoadindTable(true);

                await axios.put(
                    urlEntidadeVotacao(refDataContrato.current?.contrato_entidade.id) + '/' + refIdEditarVotacao.current
                    ,
                    obj,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );

                getVotacoes();

                setLoadingPage(false);
                setLoadindTable(false);

                handleCancelModal();
            } catch (error) {

                setLoadingPage(false);
                setLoadindTable(false);

                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };


        refForm.current
            .validateFields()
            .then((values: any) => {

                const dtIni = convertDataEHoraBrParaUS(values.dataInicio)
                const dtFim = convertDataEHoraBrParaUS(values.dataFim)

                // validar se a data inicial não poder maior que a data final
                const resultado = isAfter(new Date(dtIni)!, new Date(dtFim)!)

                if (resultado) {
                    notification.error({
                        message: 'Data inválida.',
                        description:
                            'A data inicial não pode ser posterior à data final.',
                    });
                    return
                }

                values.dataInicio = dtIni
                values.dataFim = dtFim

                requestAsync(values);

            })
            .catch((errorInfo: any) => {

                setLoadingPage(false);
                const fieldWithError = errorInfo.errorFields[0].name[0];
                refForm.current.getFieldInstance(fieldWithError).focus();

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });

            });

    }, []);


    const excluirVotacao = useCallback((id: any): void => {

        const requestAsync = async () => {
            try {
                setLoadingPage(true);
                setLoadindTable(true);

                await axios.delete(
                    urlEntidadeVotacao(refDataContrato.current?.contrato_entidade.id) + '/' + id
                    ,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );

                getVotacoes();

            } catch (error) {

                setLoadingPage(false);
                setLoadindTable(false);

                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };

        requestAsync();

    }, []);

    useEffect(() => {

        refForm.current?.setFieldsValue(
            { dataInicio: selectedDateInicio, }
        );

    }, [selectedDateInicio]);

    useEffect(() => {

        refForm.current?.setFieldsValue(
            { dataFim: selectedDateFim, }
        );

    }, [selectedDateFim]);

    const onClickAddTodosSeleCargos = useCallback(() => {

        let arrayIds: any = [];
        dadosCargosList.forEach((val: any) => {
            arrayIds.push(val.id);
        });

        refForm.current.setFieldsValue(
            { cargosPermitidosIds: arrayIds }
        );

        refBotaoSeleCargos.current?.blur();
        refFormSelectCargos.current?.blur();

    }, [dadosCargosList]);

    return {
        loadindTable,
        refContagemDoFiltroMembros,
        dataReq,
        columns,
        form,
        isModalOpen,
        handleCancelModal,
        refForm,
        loadingPage,
        onClickBotaoSalvarModal,
        onClickBotaoEditarModal,
        isEditarVotacao,
        setIsEditarVotacao,
        handleShowModal,
        setSelectedDateInicio,
        selectedDateInicio,
        setSelectedDateFim,
        selectedDateFim,
        rowSelection,
        onclickTable,
        refFormSelectCargos,
        refBotaoSeleCargos,
        dadosCargosList,
        onClickAddTodosSeleCargos,
        hasSelectedEdit,
        selectedRowKeys,
        setViewScreenAlternativas,
        viewScreenAlternativas,
        isModalOpenResultadoVotacoes,
        handleCancelModalResultadoVotacoes,
        handleShowModalResultadoVotacoes,
    }
}
