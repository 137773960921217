import { EditOutlined, InfoCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, Row, Table, Typography, notification } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { urlContratosEntidade } from "../../../../../services/request/urls";
import axios from "axios";
import { getToken } from "../../../../../services/token";
import { useSelector } from "react-redux";
import { stateGlobal } from "../../../../../interfaces/Global";
import { useHistory } from "react-router";

const PainelGestorPermissoes: React.FC = () => {

    const { Title, Text } = Typography;
    const [loadingTablePermissoes, setLoadingTablePermissoes] = useState(false);
    const [permissoes, setPermissoes] = useState([]);
    const propsState = useSelector((state: stateGlobal) => state.global);
    const refContagemDoFiltro = useRef<Array<any>>([]);
    const history = useHistory();
    const [modalPermissoesVisible, setModalPermissoesVisible] = useState(false);
    const [isEditarPermissoes, setIsEditarPermissoes] = useState(false);
    const [loadingButtonSalvar, setLoadingButtonSalvar] = useState(false);
    const [formPermissoes] = Form.useForm();
    const refFormPermissoes = useRef<any>(null);

    const getPermissoes = useCallback(() => {
        setLoadingTablePermissoes(true);
        const buscaDeDados = async () => {
            try {
                let result = await axios.get(urlContratosEntidade + "/permissao", {
                    headers: { Authorization: "Bearer " + getToken() },
                });

                setPermissoes(result.data);
            } catch (error) {
                let msgErro: any = error as Error;
                notification.error({
                    message: "Erro",
                    description: msgErro?.response?.data?.message
                        ? msgErro?.response?.data?.message
                        : "Entre em contato com o suporte!",
                });
            }
        };
        setLoadingTablePermissoes(false);
        return buscaDeDados();
    }, []);

    const comonentDidMount = useEffect((): void => {
        const buscaDadosIniciais = async () => {
            getPermissoes();
        };

        buscaDadosIniciais();
    }, []);

    const getColumnSearchProps = useCallback(
        (dataIndex: any) => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => { }}
                        placeholder={`Buscar ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            );
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm();
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={clearFilters}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: any) => (
                <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                />
            ),
            onFilter: (value: any, record: any) =>
                record[dataIndex]
                    ? record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : "",
        }),
        []
    );
    const columnsperfis = [
        {
            title: "Permissoes",
            dataIndex: "nome",
            width: "30%",
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            ...getColumnSearchProps("nome"),
            showSorterTooltip: false,
        },
        {
            title: "Ações",
            dataIndex: "id",
            width: "7%",
            render: (id: any, record: any) => (
                <span>
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        size="small"
                        onClick={() => {
                            setIsEditarPermissoes(true);

                            formPermissoes.setFieldsValue({
                                id: record.id,
                                nome: record.nome,
                                descricao: record.descricao,
                            });

                            setModalPermissoesVisible(true);
                        }}
                    >
                        <EditOutlined />
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        danger
                        size="small"
                        onClick={() => {
                            excluirPermissoes(record.id);
                        }}
                    >
                        <BiTrash />
                    </Button>
                </span>
            ),
        },
    ];

    const excluirPermissoes = useCallback((id: number) => {
        try {
            //Confirmação de exclusão
            Modal.confirm({
                title: "Excluir permissão",
                icon: <InfoCircleOutlined />,
                content:
                    "Deseja realmente excluir a Permissão? (Essa ação não pode ser desfeita)",
                okText: "Sim",
                cancelText: "Não",
                onOk() {
                    axios
                        .delete(urlContratosEntidade + "/permissao/" + id, {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                            },
                        })
                        .then((result) => {
                            notification.success({
                                message: "Sucesso",
                                description: "Permissão excluida com sucesso!",
                            });
                            getPermissoes();
                        })
                        .catch((error) => {
                            notification.error({
                                message: "Erro",
                                description:
                                    "Não foi possivel excluir a permissão!, pode estar sendo usado em algum usuário.",
                            });
                        });
                },
                onCancel() { },
            });
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
        }
    }, []);

    const salvarPermissoes = useCallback(async (values: any) => {
        try {
            setLoadingButtonSalvar(true);
            const data = {
                nome: values.nome,
                descricao: values.descricao,
            };
            if (values.id) {
                axios
                    .put(urlContratosEntidade + "/permissao/" + values.id, data, {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                        },
                    })
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Permissão atualizada com sucesso!",
                        });
                        getPermissoes();
                        setModalPermissoesVisible(false);
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Erro",
                            description: "Não foi possivel salvar a permissão!",
                        });
                    });
            } else {
                axios
                    .post(urlContratosEntidade + "/permissao", data, {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                        },
                    })
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Permissão salva com sucesso!",
                        });
                        getPermissoes();
                        setModalPermissoesVisible(false);
                    })
                    .catch((error) => {
                        notification.error({
                            message: "Erro",
                            description: "Não foi possivel salvar a permissão!",
                        });
                    });
            }
            setLoadingButtonSalvar(false);
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
            return false;
        }
    }, []);

    return (
        <>
            <Title level={2}>Painel Gestor Permissões de Usuário</Title>
            <Row style={{ marginTop: 10 }}>
                <Col
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsEditarPermissoes(false);

                            formPermissoes.setFieldsValue({
                                id: "",
                                nome: "",
                                descricao: "",
                            });

                            setModalPermissoesVisible(true);
                        }}
                    >
                        <PlusOutlined />
                        Nova Permissão
                    </Button>
                </Col>
            </Row>

            <Table
                style={{
                    marginTop: 10,
                }}
                className="marginTopTables"
                rowKey={(record) => Number(record?.id)}
                loading={loadingTablePermissoes}
                columns={columnsperfis}
                dataSource={permissoes}
                scroll={{ x: 1100 }}
                size="small"
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor:
                                    propsState.theme === "light"
                                        ? "#FAFAFA"
                                        : "#1D1D1D",
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5,
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0
                                    ? permissoes.length
                                    : refContagemDoFiltro.current.length
                                    } num total de ${permissoes.length} `}
                            </Text>
                        </div>
                    );
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: "end",
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0
                                    ? permissoes.length
                                    : refContagemDoFiltro.current.length
                                    } num total de ${permissoes.length} `}
                            </Text>
                        </div>
                    );
                }}
                pagination={{
                    locale: { items_per_page: "" },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ["topRight", "bottomRight"],
                    pageSizeOptions: ["10", "20", "50", "100"],
                }}
                locale={{
                    emptyText: (
                        <div style={{ marginTop: 10 }}>
                            <InfoCircleOutlined
                                style={{
                                    fontSize: 30,
                                    marginBottom: 10,
                                }}
                            />
                            <br />
                            <Text style={{ fontSize: 15 }}>
                                Nenhum registro encontrado!
                            </Text>
                        </div>
                    ),
                }}
            />
            <Modal
                title={isEditarPermissoes ? "Editar Permissoes" : "Novo Permissoes"}
                visible={modalPermissoesVisible}
                onCancel={() => {
                    setModalPermissoesVisible(false);
                }}
                footer={null}
                width={800}
            >
                <Form
                    form={formPermissoes}
                    ref={refFormPermissoes}
                    onFinish={(values) => {
                        salvarPermissoes(values);
                    }}
                    style={{ marginTop: 10 }}
                    layout="vertical"
                >
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="nome"
                        label="Nome"
                        rules={[
                            {
                                required: true,
                                message: "Campo obrigatório!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="descricao"
                        label="Descrição"
                        rules={[
                            {
                                required: true,
                                message: "Campo obrigatório!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Row>
                        <Col span={24} style={{ textAlign: "end" }}>
                            <Button
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    setModalPermissoesVisible(false);
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loadingButtonSalvar}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default PainelGestorPermissoes;
